import React, { Fragment, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import c from 'assets/images/c.png';
import s from 'assets/images/s.png';
import r from 'assets/images/r.png';
import e from 'assets/images/e.png';
import rarity from 'assets/images/l.png';
// import { Button } from 'antd';
const SeriaContent = (props) => {
  const { dropItem, arrayTotal } = props;
  const [sortDropItem, setSort] = useState([]);
  useEffect(() => {
    if (dropItem) {
      dropItem.sort(function (a, b) {
        return a.nft_type_id - b.nft_type_id;
      });
      setSort(dropItem);
    }
  }, [dropItem]);
  const genClass = (data) => {
    if (data) {
      if (data.nft_type_id == 1) {
        return 'rarity_btn rarity_legend mt-2';
      }
      if (data.nft_type_id == 2) {
        return 'rarity_btn rarity_epic mt-2';
      }
      if (data.nft_type_id == 3) {
        return 'rarity_btn rarity_super_rare mt-2';
      }
      if (data.nft_type_id == 4) {
        return 'rarity_btn rarity_rare mt-2';
      }
      if (data.nft_type_id == 5) {
        return 'rarity_btn rarity_common mt-2';
      }
      return '';
    }
  };
  const genClassName = (data) => {
    if (data) {
      if (data.nft_type_id == 1) {
        return 'Legendary';
      }
      if (data.nft_type_id == 2) {
        return 'Epic';
      }
      if (data.nft_type_id == 3) {
        return 'Super rare';
      }
      if (data.nft_type_id == 4) {
        return 'Rare';
      }
      if (data.nft_type_id == 5) {
        return 'Common';
      }
      return '';
    }
  };
  return (
    <Fragment>
      <div className='row table-series'>
        <Table className='col-12'>
          <thead>
            <tr>
              <th>Edition</th>
              <th>Amount</th>
              <th>Total minted</th>
              <th>Probability</th>
            </tr>
          </thead>
          <tbody>
            {dropItem &&
              dropItem.map((data, key) => {
                return (
                  <tr key={key}>
                    <td scope='row pt-2' className="pt-2">
                      <h5 className={genClass(data)}>{genClassName(data)}</h5>
                    </td>
                    <td>{data.amount}</td>
                    <td>{data.total_supply}</td>
                    <td>{(parseFloat(data.total_supply / arrayTotal.totalSupply) * 100).toFixed(2)} %</td>
                  </tr>
                );
              })}
            <tr className="footer-row">
              <td>Total</td>
              <td>{arrayTotal ? arrayTotal.totalAmount : ''}</td>
              <td>{arrayTotal ? arrayTotal.totalSupply : ''}</td>
              <td>100%</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};
export default SeriaContent;
