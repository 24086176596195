import React, { Fragment } from 'react';
import avatar from 'assets/images/person.png';
import { Timeline } from 'antd';
import { fake_owner } from 'assets/images';

import './item.scss';
import { API_URI_LOOTBOX } from 'env';
import { handlePriceItem, shorterName } from 'utils/common';

export default function HistoryLineItemOwner(props) {
  const { items } = props;
  return (
    <Fragment>
      <Timeline className='itemSaleHistory'>
        {items &&
          items.map((m, key) => {
            return (
              <Timeline.Item className='event'  style={{display:m.amount >0?"":"none"}} key={key}>
                <div className='history-item d-flex check20'>
                  <img src={m.user_avatar && m.user_avatar != ""?m.user_avatar:avatar} width='38px' height='38px' alt='' />
                  <div className='name-time d-flex flex-column col-9'>
                    <span className='action'>{shorterName(m.user_fullname)}</span>
                    <span className='time'>
                      {m.amount} {m.amount > 1 ? 'editions' : 'edition'}
                    </span>
                  </div>
                </div>
              </Timeline.Item>
            );
          })}
      </Timeline>
    </Fragment>
  );
}
