import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvatarEditor, Loading } from 'components';
import { Upload, message } from 'antd';
import { profileAction } from 'actions/profile';
import { userService } from 'services/user';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

const UserCover = ({ user, editable = false }) => {
  const classes = useStyles();

  const inputEl = React.useRef();
  const { ...profile } = useSelector(({ profile }) => user ?? profile);

  const [file, setFile] = React.useState();
  const [isOpenAvatar, setIsOpenAvatar] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChooseFile = ({ file }) => {
    if (file.size / 1024 / 1024 >= 1) {
      message.error('File size maximum is 1mb');
    } else if (!file.type.startsWith('image')) {
      message.error('File type is not allowed');
    } else {
      setFile(file);
      setIsOpenAvatar(true);
    }
  };

  const handleClickSave = async () => {
    if (inputEl) {
      const canvasScaled = inputEl.current.getImage();
      const imageUrl = canvasScaled.toDataURL();
      const blob = await fetch(imageUrl).then((res) => res.blob());

      const formData = new FormData();
      formData.append('cover', new File([blob], file.name));

      setIsLoading(true);
      userService
        .updateProfile(formData)
        .then(async () => {
          await profileAction.fetchProfile();
          setIsOpenAvatar(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Container className={classes.container} style={{ paddingTop: 360 }}>
      <Box
        height={480}
        className={classes.cover}
        style={profile.cover && !isOpenAvatar ? { backgroundImage: `url(${profile.cover})` } : {}}
      >
        {isOpenAvatar && (
          <AvatarEditor
            ref={inputEl}
            image={file}
            width={1440 - 48}
            height={480}
            border={0}
            className={classes.coverCanvas}
          />
        )}
        <Box className={classes.corverBottom}>
          {isOpenAvatar ? (
            <>
              <Button variant='contained' style={{ zIndex: 2, marginRight: 12 }} onClick={() => setIsOpenAvatar(false)}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='secondary'
                disabled={isLoading}
                style={{ zIndex: 2 }}
                startIcon={<Loading visible={isLoading} icon={<CheckOutlinedIcon />} />}
                onClick={handleClickSave}
              >
                Save Changes
              </Button>
            </>
          ) : (
            editable && (
              <Upload accept='image/*' showUploadList={false} customRequest={handleChooseFile}>
                <Button variant='contained' style={{ zIndex: 2 }} startIcon={<CameraAltOutlinedIcon />}>
                  Update Cover Photo
                </Button>
              </Upload>
            )
          )}
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  cover: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    position: 'absolute',
    top: 0,
    left: 24,
    right: 24,
    borderRadius: 16,
    overflow: 'hidden',
  },
  coverCanvas: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  corverBottom: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 100,
    padding: 24,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    background: 'linear-gradient(#fff0, #000a)',
  },
}));

export default UserCover;
