import { store } from 'reducers';
import { ActionType } from 'reducers/coreui';

export const DARKMODE = 'darkmode';
export const LANGUAGE = 'language';

const updateDarkmode = (darkmode) => {
  store.dispatch({
    type: ActionType.GET_DARKMODE,
    data: darkmode,
  });
  localStorage.setItem(DARKMODE, darkmode);
};

const updateLanguage = (language) => {
  store.dispatch({
    type: ActionType.GET_LANGUAGE,
    data: language,
  });
  localStorage.setItem(LANGUAGE, language);
};

const updateChainId = (chainId) => {
  store.dispatch({
    type: ActionType.GET_CHAIN_ID,
    data: chainId,
  });
};

export const coreuiAction = {
  updateDarkmode,
  updateLanguage,
  updateChainId,
};
