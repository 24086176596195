import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { List, ListItem, ListItemText, Hidden, Paper, Link as NavLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'antd';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';

const Menu = ({ onClickMenu }) => {
  const classes = useStyles();
  const { payments } = useSelector(({ system }) => system);
  const { explore } = privateRoute;

  const MenuItem = ({ visible = true, name, path, onClick }) => {
    const location = useLocation();
    return visible ? (
      <ListItem
        button
        component={Link}
        to={path ?? '/'}
        className={classes.item}
        selected={location.pathname.startsWith(path)}
        onClick={() => {
          if (onClick) onClick();
          else if (onClickMenu) onClickMenu();
        }}
      >
        <ListItemText primary={name} />
      </ListItem>
    ) : null;
  };

  return (
    <>
      <ListItem button className={classes.item} component={Link} color='inherit' to='/app/market'>
        <ListItemText primary='Loot Box' />
      </ListItem>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  item: {
    width: 'unset',
    height: 40,
    marginRight: 8,
    backgroundColor: '#fff !important',
    '&:hover': {
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 24,
        height: 2,
        backgroundColor: color.link,
      },
    },

    '&.Mui-selected': {
      '&:after': {
        content: "''",
        position: 'absolute',
        bottom: 0,
        width: 24,
        height: 2,
        backgroundColor: color.link,
      },
    },

    '& .MuiListItemText-root': {
      whiteSpace: 'nowrap',
    },
  },
}));

export default Menu;
