/* eslint-disable react-hooks/exhaustive-deps */
import { MenuFoldOutlined, MenuUnfoldOutlined, RightOutlined } from '@ant-design/icons';
import {
  Button,
  List,
  Menu,
  Select,
  Space,
  Collapse,
  Tag,
  Radio,
  Slider,
  Input,
  InputNumber,
  Checkbox,
  Col,
  Row,
  Pagination,
} from 'antd';

import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import opened from '../../assets/images/opened.png';
import robot from '../../assets/images/robot.png';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { images } from '../../assets/images';
import './boxmarket.scss';
import DropItem from './ItemMarket';
import { lbprofileAction } from 'actions/lootbox/profile';
import { lootboxService } from 'services/lootbox/lootbox';
import { campaignService } from 'services/lootbox/campaign';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { MAX_PRICE, MIN_PRICE } from 'env';
import moment from 'moment'
import { handlePriceItem, shorterName } from 'utils/common';
const { SubMenu } = Menu;
const { Option } = Select;
const { Panel } = Collapse;

const BoxMarket = (props) => {
  const { profile, lootbox = [] } = props;
  const width = useWindowDimensions().width;
  const [current, setCurrent] = useState();
  const [dropdownOpen, setDropDownOpen] = useState('mail');
  const [type, setType] = useState('open');
  const [typeMoney, setTypeMoney] = useState(null);
  const [image, setImage] = useState(lootbox);
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItem] = useState([]);
  const [price, setPrice] = useState([]);
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [marketBox, setMarketBox] = useState([]);
  const [marketItem, setMarketItem] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const { currencySystem } = useSelector(({ common }) => common);
  const [sort, setSort] = useState('created_at desc');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [campaignTitle, setCampaignTitle] = useState('');
  const [collection, setCollection] = useState(null);
  const [lootboxCampaignId, setLootboxCampaignId] = useState(null);
  const [campaignids, setCampaignIds] = useState([]);
  const [loadingSort,setLoadingSort] = useState(false)
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);

  const afterOnChange = (value) => {
    handleChange(value[0], value[1]);
    if (value[0] != min) {
      setMin(value[0]);
    }
    if (value[1] != max) {
      setMax(value[1]);
    }
  };
  const onChange = (value) => {
    if (value[0] != min) {
      setMin(value[0]);
    }
    if (value[1] != max) {
      setMax(value[1]);
    }
  };

  useEffect(async () => {
    const campaign = await campaignService.campaignSearch();
    let filter = [];
    if (campaign && campaign.data && campaign.data.length > 0) {
      campaign.data.slice(0, 10).map((m) => {
        if (m.title != '') {
          const t = {
            name: m.title,
            id: m.id,
          };
          filter.push(t);
        }
      });
      setCollection(filter);
    }
  }, []);
  const handleSortItemGen=(item)=>{
    console.log("test:",loadingSort)
    if(item == null || item.length ==0 || loadingSort ){
      return [];
    }
    if(sort == "created_at desc"){
      item.sort(function(a, b) {
        return  moment(b.updated_at).unix() - moment(a.updated_at).unix();
      });
    }
    if(sort == "price asc"){
      item.sort(function(a, b) {
        const tempA = a.type == "box"?parseFloat(a.resell_price):handlePriceItem(a.price,a.currency_decimal)
        const tempB = b.type == "box"?parseFloat(b.resell_price):handlePriceItem(b.price,b.currency_decimal)
        return  tempA - tempB;
      });
    }
    if(sort == "price desc"){
      item.sort(function(a, b) {
        const tempA = a.type == "box"?parseFloat(a.resell_price):handlePriceItem(a.price,a.currency_decimal)
        const tempB = b.type == "box"?parseFloat(b.resell_price):handlePriceItem(b.price,b.currency_decimal)
        return  tempB - tempA;
      });
    }
    return item;
  }

  console.log("sdfddddđ:",loadingSort)

  const handleSearch = async () => {
    const campaign = await campaignService.campaignSearch();
    let filter = [];
    const data = campaign.data.filter((f) => f.title.toLowerCase().includes(campaignTitle.toLowerCase()));
    data.slice(0, 10).map((m) => {
      if (m.title != '') {
        const t = {
          name: m.title,
          id: m.id,
        };
        filter.push(t);
      }
    });
    setCollection(filter);
  };

  const onChangeMin = (value) => {
    setMin(value);
    handleChange(value, max);
  };
  const onChangeMax = (value) => {
    setMax(value);
    handleChange(min, value);
  };

  const text =
    'A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.';

  function preventDefault(e) {
    e.preventDefault();
    console.log('Clicked! But prevent default.');
  }

  const history = useHistory();

  useEffect(() => {
    setLoadingSort(false)
    handleType(type);
  }, [marketItem, marketBox]);

  useEffect(() => {
    handleChange(min, max);
  }, [campaignids]);

  useEffect(async () => {
    const param = {
      sort: sort,
      page: page,
      pagesize: 12,
      'currency_ids[]': typeMoney ? typeMoney : undefined,
    };
    let res = null;
    try {
      res = await lootboxService.marketBoxQS(qs.stringify(param) + convertToParamList(campaignids));
      setMarketBox(res.data);
    } catch {}

    const res1 = await lootboxService.marketItemQS(qs.stringify(param) + convertToParamList(campaignids));
    setMarketItem(res1.data);
    if (type === 'box') {
      if (res) {
        setTotal(res.total);
      }
    } else if (type === 'item') {
      if (res1) {
        setTotal(res1.total);
      }
    } else {
      if (res && res1) {
        if (res1.total > res.total) {
          setTotal(res1.total);
        } else {
          setTotal(res.total);
        }
      }
    }
    
  }, [sort, page, type, typeMoney]);
  const handleSortChange = (e) => {
    setLoadingSort(true)
    setSort(e);
  };
  const handleRedirect = (id, typ) => {
    if (!isLoggedIn) {
      lbprofileAction.connect();
      return;
    }
    if (typ == 'box') {
      history.push('/app/box-detail/' + id);
    }
    if (typ == 'item') {
      history.push('/app/detail-item/' + id);
    }
  };

  const makeUrl = (id, typ) => {
    // if(!isLoggedIn){
    //     lbprofileAction.connect()
    // }
    if (typ == 'box') {
      return '/app/box-detail/' + id;
    }
    if (typ == 'item') {
      return '/app/detail-item/' + id;
    }
  };

  const convertToParamList = (values) => {
    if (!values || values.length <= 0) {
      return '';
    }
    let param = '';
    values.map((m) => {
      param = param + '&lootbox_campaign_ids[]=' + m;
    });
    return param;
  };
  const handleChange = async (mi, ma) => {
    const param = {
      from_price: mi || 0,
      to_price: ma || MAX_PRICE,
      page: 1,
      pagesize: 12,
      sort: sort,
      'currency_ids[]': typeMoney ? typeMoney : undefined,
      campaign_title: campaignTitle != null && campaignTitle != '' ? campaignTitle : undefined,
    };
    let res1 = null;
    let res = null;
    try {
      res = await lootboxService.marketBoxQS(qs.stringify(param) + convertToParamList(campaignids));
      if (res) {
        setMarketBox(res.data);
      } else {
        setMarketBox([]);
      }
    } catch (error) {
      setMarketBox([]);
    }
    try {
      res1 = await lootboxService.marketItemQS(qs.stringify(param) + convertToParamList(campaignids));
      if (res1) {
        setMarketItem(res1.data);
      } else {
        setMarketItem([]);
      }
    } catch (error) {
      setMarketItem([]);
    }

    //
    if (type === 'box') {
      if (res) {
        setTotal(res.total);
      }
    } else if (type === 'item') {
      if (res1) {
        setTotal(res1.total);
      }
    } else {
      if (res && res1) {
        if (res1.total > res.total) {
          setTotal(res1.total);
        } else {
          setTotal(res.total);
        }
      }
    }
  };
  const handleCheckBox = (e) => {
    let temp = [];
    if (campaignids.includes(e.target.value)) {
      temp = campaignids.filter((f) => f != e.target.value);
    } else {
      temp = [...campaignids];
      temp.push(e.target.value);
    }
    setCampaignIds(temp);
  };
  const handleType = (typ) => {
    let res = [];

    if (typ == 'box') {
      let itemTemp = [];
      marketBox.map((m) => {
        const t = { ...m, type: 'box' };
        itemTemp.push(t);
      });
      setItem(handleSortItemGen(itemTemp));
    }
    if (typ == 'item') {
      let itemTemp = [];
      marketItem.map((m) => {
        const t = { ...m, type: 'item' };
        itemTemp.push(t);
      });
      setItem(handleSortItemGen(itemTemp));
    }
    if (typ == 'open') {
      let itemTemp1 = [];
      marketBox.map((m) => {
        const t = { ...m, type: 'box' };
        itemTemp1.push(t);
      });
      let itemTemp2 = [];
      marketItem.map((m) => {
        const t = { ...m, type: 'item' };
        itemTemp2.push(t);
      });
      const array = itemTemp1.concat(itemTemp2);
      setItem(handleSortItemGen(array));
    }

    setType(typ);
    setCollapsed(false);
  };
  const handleValueCampaign = () => {
    let result = [];
    if (campaignids && collection) {
      collection.map((m) => {
        if (campaignids.includes(m.id + '')) {
          result.push(m.name);
        }
      });
    }
    return result;
  };
  const handleClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
  const toggle = () => {
    setDropDownOpen(!dropdownOpen);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const handleEmptyItem = () => {
    setItem([]);
  };
  function callback(key) {
    console.log(key);
  }
  const handleChangeMuti = (e) => {
    console.log('erwer:', e);
  };
  // const handleCampaignWithName=(data)=>{
  //     let result = []
  //     if(data && data.length> 0){
  //         collection.map(m =>{
  //             if(data.includes(m.name)){
  //                 result.push(m.id);
  //             }
  //         })
  //     }
  //     setCampaignIds(result)

  // }

  const handleRemove = (id) => {
    const filter = campaignids.filter((f) => f != id);
    if (filter) {
      setCampaignIds(filter);
    } else {
      setCampaignIds([]);
    }
  };
  return (
    <Fragment>
      <div className='row align-items-center blk-market-left'>
        <div className='col-3 pl-0'>
          <img src={images.fire_icon} className='icon-title d-none' />
          <div className='title'>Loot Box Market</div>
        </div>
        <div className='col-9 pr-0'>
          <div className='box-profile-main d-flex'>
            <div>
              {collection &&
                collection.map((m) => {
                  if (campaignids.includes(m.id.toString())) {
                    return (
                      <Button className='btn-close' style={{ marginBottom: 10 }} key={m.id}>
                        {shorterName(m.name)}
                        <div className='close-campaign' onClick={() => handleRemove(m.id.toString())}>
                          X
                        </div>
                      </Button>
                    );
                  }
                })}
              {campaignids && campaignids.length > 0 && (
                <Button
                  style={{ color: '#5243AA', border: 'none' }}
                  onClick={() => setCampaignIds([])}
                  className='btn-close'
                >
                  Clear all
                </Button>
              )}
            </div>

            <Space style={{ alignItems: 'start', maxHeight: 50, marginRight: 20 }}>
              <Select
                onChange={(value) => setTypeMoney(value)}
                defaultValue='Currency'
                style={{ width: '120px', alignItems: 'start' }}
              >
                {currencySystem &&
                  currencySystem.map((m) => {
                    return (
                      <Option key={m.id} value={m.id}>
                        {m.label}
                      </Option>
                    );
                  })}
              </Select>
              <Select
                onChange={(e) => {
                  handleSortChange(e);
                }}
                defaultValue='Sort'
                style={{ width: '120px', alignItems: 'start' }}
              >
                <Option value='created_at desc'>Recently added</Option>
                <Option value='price asc'>Lowest price</Option>
                <Option value='price desc'>Highest price</Option>
              </Select>
            </Space>
          </div>
        </div>
      </div>
      <div className='row justify-content-between mt-2'>
        <div className='bkl-filter-wrapper' style={{ height: 'fit-content' }}>
          <div className='row col-12'>
            <div className='row col-12 bkl-filter-title'>Filter</div>
            <Collapse
              bordered={false}
              ghost={true}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />}
              className='site-collapse-custom-collapse col-12'
              expandIconPosition={'right'}
            >
              <Panel header='Type' key='1' showArrow='true' className='site-collapse-custom-panel col-12'>
                <Checkbox
                  className='col-6'
                  checked={type === 'open' ? true : false}
                  onChange={() => {
                    handleType('open');
                    setPage(1);
                  }}
                >
                  All
                </Checkbox>
                <Checkbox
                  className='col-6'
                  checked={type === 'box' ? true : false}
                  onChange={() => {
                    handleType('box');
                    setPage(1);
                  }}
                >
                  Boxes
                </Checkbox>
                <Checkbox
                  className='col-6'
                  checked={type === 'item' ? true : false}
                  onChange={() => {
                    handleType('item');
                    setPage(1);
                  }}
                >
                  Items
                </Checkbox>
              </Panel>
              <Panel
                header='Campaign'
                key='2'
                showArrow='true'
                className='site-collapse-custom-panel ant-collapse-item-active'
              >
                <Input.Group compact>
                  <Input.Search
                    onSearch={() => {
                      handleSearch();
                    }}
                    style={{ width: '100%' }}
                    onChange={(e) => setCampaignTitle(e.target.value)}
                  />
                </Input.Group>
                <Row className='campaign-list'>
                  {collection &&
                    collection.map((m, key) => {
                      return (
                        <Col key={m.id} span={24} className='mb-10 mt-10'>
                          <Checkbox
                            checked={campaignids.includes(m.id.toString())}
                            onClick={(e) => handleCheckBox(e)}
                            value={m.id}
                          >
                            {m.name}
                          </Checkbox>
                        </Col>
                      );
                    })}
                </Row>
              </Panel>
              <Panel
                header='Price'
                key='3'
                showArrow='true'
                className='site-collapse-custom-panel ant-collapse-item-active'
              >
                <Select
                  defaultValue='Currency'
                  onChange={(value) => setTypeMoney(value)}
                  className='coin p-0 mb-2'
                  style={{ width: '100%', alignItems: 'start', backgroundColor: '#FAFAFC' }}
                >
                  {currencySystem &&
                    currencySystem.map((m) => {
                      return (
                        <Option key={m.id} value={m.id}>
                          {m.label}
                        </Option>
                      );
                    })}
                </Select>

                {/* <Slider defaultValue={[1, 10]} reverse={ false } min={ 0 } max={ 10000000 } onChange={onChange} onAfterChange={afterOnChange} range={true} value={[min, max] } /> */}
                <Row>
                  <Col span={11}>
                    <InputNumber
                      className='min-input-main col-12'
                      style={{ width: '100%' }}
                      min={MIN_PRICE}
                      max={MAX_PRICE}
                      value={min}
                      placeholder='Min'
                      onChange={onChangeMin}
                    />
                  </Col>
                  <Col span={2}>
                    <span className='Label_To'>-</span>
                  </Col>
                  <Col span={11}>
                    <InputNumber
                      className='max-input-main col-12'
                      min={MIN_PRICE}
                      style={{ width: '100%' }}
                      max={MAX_PRICE}
                      value={max}
                      placeholder='Max'
                      onChange={onChangeMax}
                    />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div className='blk-content-wrapper'>
          <List
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={items}
            renderItem={(i) => (
              <Fragment>
                <List.Item className='market-item' onClick={() => {}}>
                  <Link to={makeUrl(i.id, i.type)}>
                    <DropItem item={i} type={type} />
                  </Link>
                </List.Item>
              </Fragment>
            )}
          />
          {total && total > 12 ? (
            <Pagination
              style={{ textAlign: 'center' }}
              defaultCurrent={1}
              onChange={(page, size) => {
                setPage(page);
              }}
              pageSize={12}
              total={total}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default BoxMarket;
