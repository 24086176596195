import { Link } from 'react-router-dom';
import { Avatar, Box, Card, CardMedia, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { privateRoute } from 'routes';

import Top10Frame from 'assets/frames/Top10.svg';
import Top3Frame from 'assets/frames/Top3.svg';
import CrownIcon from 'assets/frames/Crown.svg';

const SellerPosition = ({ index }) => {
  const classes = useStyles();
  return (
    <Box className={classes.position}>
      <Box
        className='flex-center'
        style={{ position: 'absolute', inset: -1, background: `url(${index < 3 ? Top3Frame : Top10Frame})` }}
      >
        <span className={classes.textGradient} style={{ fontSize: index < 9 ? 12 : 10, marginBottom: 1 }}>
          {index === 0 ? <img src={CrownIcon} /> : index + 1}
        </span>
      </Box>
    </Box>
  );
};

const CardUser = ({ user, index }) => {
  const classes = useStyles();

  return (
    <Card variant='outlined' className='Card-Container'>
      <CardMedia
        className={classes.cardMedia}
        image={user.cover}
        component={Link}
        to={privateRoute.artist.url(user.address)}
      >
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Box className={classes.box}>
        <Link to={privateRoute.artist.url(user.address)} className='mr-8' style={{ position: 'relative' }}>
          <SellerPosition index={index} />
          <Avatar src={user.avatar} className={classes.avatar} />
        </Link>
        <Box flex={1} pt={1.5} overflow='hidden'>
          <Typography variant='subtitle1' className='text-ellipsis' title={user.username}>
            <Link to={privateRoute.artist.url(user.address)}>{user.username}</Link>
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {user.totalSell} {user.paymentTokenName}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const CardSkeleton = () => {
  const classes = useStyles();
  return (
    <Card variant='outlined' className={classes.card}>
      <CardMedia className={classes.cardMedia}>
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Box className={classes.box}>
        <Avatar className={classes.avatar} style={{ marginRight: 8, opacity: 0.75 }} />
        <Box flex={1} pt={1.5}>
          <Skeleton />
          <Skeleton />
        </Box>
      </Box>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
  },
  cardMedia: {
    position: 'relative',
    height: 100,
    zIndex: 1,
  },
  box: {
    display: 'flex',
    padding: '8px 12px',
    marginTop: -16,
  },
  avatar: {
    width: 60,
    height: 60,
    border: '2px solid #fff',
    zIndex: 1,
  },
  position: {
    width: 22,
    height: 22,
    borderRadius: '50%',
    position: 'absolute',
    bottom: 2,
    right: 2,
    zIndex: 2,
    backgroundColor: '#fff',
  },
  textGradient: {
    background: 'linear-gradient(#ffde00 0%, #fd5900 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
}));

CardUser.Skeleton = CardSkeleton;

export default CardUser;
