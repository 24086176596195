import React from 'react';
import { Link } from 'react-router-dom';
import { AppHeader, AppFooter } from 'containers';
import { Box, Typography } from '@material-ui/core';
import { privateRoute } from 'routes';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return { isError: true };
  }

  render() {
    if (this.state.isError) {
      return (
        <div className='App Private-Layout'>
          <AppHeader />
          <div className='App-Body'>
            <Box mt={10} textAlign='center'>
              <Typography>There are some errors occurred</Typography>
              <Typography gutterBottom>Please try again later</Typography>
              <Typography
                component={Link}
                color='secondary'
                to={privateRoute.explore.path}
                onClick={() => {
                  this.setState({ isError: false });
                  window.location.reload();
                }}
              >
                Refresh
              </Typography>
            </Box>
          </div>
          <AppFooter />
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
