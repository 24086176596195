import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Button, Container, Collapse, Typography, Paper, Box, Dialog } from '@material-ui/core';
import { FormGroup, TextField, MenuItem, Card, CardMedia, Avatar, Divider, Hidden } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { Col, Row } from 'antd';
import { InputNumberFormat, ColorButton, CountdownTimer } from 'components';
import { profileAction } from 'actions/profile';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import { moment, onFormError } from 'utils/common';
import { color, DDMMYYYY_HHMM } from 'utils/constants';
import { privateRoute } from 'routes';

import { PopupCreate } from 'views/Create/components';

import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import TimelapseOutlinedIcon from '@material-ui/icons/TimelapseOutlined';

const CREATE_TYPES = [
  {
    id: 1,
    name: 'Fixed Price',
    code: false,
    description: 'Enter price to allow users instantly purchase your NFT',
    icon: <LocalOfferOutlinedIcon />,
  },
  {
    id: 2,
    name: 'Timed Auction',
    code: true,
    description: 'Set a period of time for which buyers can place bids',
    icon: <TimelapseOutlinedIcon />,
  },
];

const CreateView = () => {
  const history = useHistory();
  const { id } = useParams();
  const { categories, subCategories, payments } = useSelector(({ system }) => system);
  const { ...profile } = useSelector(({ profile }) => profile);

  const [apiItem, setItem] = React.useState({});
  const nftItem = React.useMemo(() => normalizeItem(apiItem, profile), [apiItem, profile]);

  const { control, handleSubmit, watch } = useForm({ mode: 'onChange' });

  const [isOpenCreate, setIsOpenCreate] = React.useState(false);
  const [createValues, setCreateValues] = React.useState(false);

  const { isPutOnMarket = true, price, reservedPrice, paymentTokenID } = watch();
  const { isAuction, startTime, endTime } = watch();

  const fetchData = React.useCallback(() => {
    marketService.getItem({ id }).then(({ data }) => {
      setItem(data);
    });
  }, [id]);

  const handleClickCreate = () => {
    handleSubmit((values) => {
      profileAction.connect(() => {
        setCreateValues({
          ...{ isPutOnMarket, price, reservedPrice, paymentTokenID },
          ...{ isAuction, startTime, endTime },
          paymentToken: payments.find((next) => next.id === paymentTokenID),
        });
        setIsOpenCreate(true);
      });
    }, onFormError)();
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth='md'>
      <Paper className='p-24'>
        <Typography variant='h4' gutterBottom>
          Information collectible
        </Typography>
        <Row gutter={24}>
          <Col sm={16}>
            <FormGroup>
              <TextField InputProps={{ readOnly: true }} label='Name' value={nftItem.name ?? ''} />

              <TextField
                multiline
                InputProps={{ readOnly: true }}
                label='Description'
                value={nftItem.description ?? ''}
              />

              <Row gutter={24}>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label='Category'
                    value={categories.find((next) => next.id === nftItem.categoryID)?.name ?? ''}
                  />
                </Col>
                <Col style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    InputProps={{ readOnly: true }}
                    label='Sub Category'
                    value={subCategories.find((next) => next.id === nftItem.subCategoryID)?.name ?? ''}
                  />
                </Col>
              </Row>

              <Box className='justify-content-between align-items-center mb-24'>
                <Typography variant='h6'>Put on Marketplace</Typography>
              </Box>
              <MarketForm {...{ control, isPutOnMarket, isAuction, startTime }} />

              <ColorButton size='large' onClick={handleClickCreate}>
                PUT ON MARKET
              </ColorButton>
              <Dialog open={isOpenCreate} maxWidth='md'>
                <PopupCreate
                  item={nftItem}
                  formValues={createValues}
                  onSuccess={(id) => {
                    setIsOpenCreate(false);
                    history.push(privateRoute.exploreView.url(id));
                  }}
                  onClose={() => setIsOpenCreate(false)}
                />
              </Dialog>
            </FormGroup>
          </Col>
          <Col sm={8}>
            <Hidden xsDown>
              <Card variant='outlined' style={{ borderRadius: 8, position: 'sticky', top: 60 + 24 }}>
                <CardMedia image={nftItem.isVideo ? nftItem.preview : nftItem.image} style={{ height: 240 }}>
                  <Skeleton
                    variant='rect'
                    width='100%'
                    height='100%'
                    animation={false}
                    style={{ backgroundColor: '#aaa1' }}
                  />
                </CardMedia>
                <Divider />
                <Box style={{ padding: '8px 12px 4px' }}>
                  <Typography variant='subtitle1' className='text-ellipsis' title={nftItem.name}>
                    {nftItem.name}
                  </Typography>
                </Box>
                <Box className='align-items-center' style={{ padding: '4px 12px 8px' }}>
                  <Avatar src={profile.avatar} style={{ width: 28, height: 28, marginRight: 8 }} />
                  <Typography variant='body2' color='textSecondary'>
                    {profile.username}
                  </Typography>
                </Box>
                <Divider className='mx-12 my-4' />
                <Box className='justify-content-between align-items-start' style={{ padding: '8px 12px 12px' }}>
                  <Typography variant='subtitle1'>
                    {price ?? 0} {payments.find((next) => next.id === paymentTokenID)?.symbol}
                  </Typography>
                  {isAuction && <CountdownTimer endTime={endTime.isValid() && endTime.unix()} />}
                </Box>
              </Card>
            </Hidden>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export const MarketForm = ({ control, isPutOnMarket, isAuction, startTime }) => {
  const { payments } = useSelector(({ system }) => system);

  const PaymentTokenSelect = () => (
    <Controller
      name='paymentTokenID'
      defaultValue={payments[0]?.id}
      control={control}
      render={({ field }) => (
        <TextField {...field} required select style={{ minWidth: 90 }}>
          {payments.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.symbol}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );

  return (
    <>
      <Box className='mb-24'>
        <Typography color='textSecondary' gutterBottom>
          {CREATE_TYPES.find((item) => item.code === isAuction)?.description}
        </Typography>
        <Controller
          name='isAuction'
          defaultValue={false}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Row gutter={[24, 12]}>
              {CREATE_TYPES.map((item) => {
                const checked = item.code === value;
                return (
                  <Col span={8} key={item.id}>
                    <Button
                      fullWidth
                      variant='outlined'
                      style={{
                        padding: 16,
                        borderWidth: 2,
                        borderColor: checked ? color.primary : color.alice,
                        backgroundColor: checked ? color.primaryLight : color.white,
                      }}
                      onClick={() => onChange(item.code)}
                    >
                      <Box className='flex-column align-items-center'>
                        {item.icon}
                        <Typography>{item.name}</Typography>
                      </Box>
                    </Button>
                  </Col>
                );
              })}
            </Row>
          )}
        />
      </Box>

      <Row gutter={24}>
        <Col style={{ flex: 1 }}>
          <Controller
            name='price'
            defaultValue=''
            control={control}
            rules={{
              validate: isPutOnMarket ? { required: (value) => (value && value > 0) || 'Price is required' } : {},
            }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                required
                fullWidth
                label='Price'
                InputProps={{
                  inputComponent: InputNumberFormat,
                }}
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Col>
        <Col>
          <PaymentTokenSelect control={control} />
        </Col>
      </Row>

      <Collapse in={isAuction}>
        <Row gutter={24}>
          <Col style={{ flex: 1 }}>
            <Controller
              name='reservedPrice'
              defaultValue=''
              control={control}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Reserved Price'
                  InputProps={{
                    inputComponent: InputNumberFormat,
                  }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Col>
          <Col>
            <PaymentTokenSelect control={control} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col style={{ flex: 1 }}>
            <Controller
              name='startTime'
              defaultValue={moment().add(10, 'minutes')}
              control={control}
              rules={{
                validate: isAuction
                  ? {
                      isValid: (value) => (value && value.isValid()) || 'Invalid Date Format',
                      isAfter: (value) => value.isAfter(moment()) || 'Starting should be after current time',
                    }
                  : {},
              }}
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  required
                  label='Starting Date'
                  placeholder={DDMMYYYY_HHMM}
                  format={DDMMYYYY_HHMM}
                  minDate={moment()}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Controller
              name='endTime'
              defaultValue={moment().add(10, 'days')}
              control={control}
              rules={{
                validate: isAuction
                  ? {
                      isValid: (value) => (value && value.isValid()) || 'Invalid Date Format',
                      isAfter: (value) => value.isAfter(startTime) || 'Expiration should be after starting date',
                      min: (v) => v.diff(startTime, 'minutes') >= 2 || 'Auction duration is at least 2 minutes',
                      max: (value) => value.diff(startTime, 'days') < 14 || 'Auction duration is at most 14 days',
                    }
                  : {},
              }}
              render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
                <KeyboardDateTimePicker
                  {...field}
                  required
                  label='Expiration Date'
                  placeholder={DDMMYYYY_HHMM}
                  format={DDMMYYYY_HHMM}
                  minDate={moment()}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

export default CreateView;
