import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Box, Dialog, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Upload, message } from 'antd';
//import { profileAction } from 'actions/profile';
import { color } from 'utils/constants';
import UserAvatarCrop from './UserAvatarCrop';
import { shortenAddress } from 'utils/common';
import { lbprofileAction } from 'actions/lootbox/profile';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { API_URI_LOOTBOX } from 'env';

const UserAvatar = ({ user, editable = false, size = 210, border = 6, account }) => {
  const classes = useStyles();
  const { ...profile } = useSelector(({ profile }) => user ?? profile);

  const [file, setFile] = React.useState();
  const [isOpenAvatar, setIsOpenAvatar] = React.useState(false);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const [image, setImage] = useState(null);

  const handleChooseFile = ({ file }) => {
    if (file.size / 1024 / 1024 >= 2) {
      message.error('File size maximum is 2mb');
    } else if (!file.type.startsWith('image')) {
      message.error('File type is not allowed');
    } else {
      setFile(file);
      setIsOpenAvatar(true);
    }
  };

  const handleSuccess = async () => {
    setIsOpenAvatar(false);
    lbprofileAction.fetchProfile();
  };

  // useEffect(()=>{
  //   if(lootboxProfile){
  //     setImage(lootboxProfile.avatar)
  //   }
  // },[lootboxProfile])

  return (
    <div className='row col-12'>
      <div className='row col-5'>
        <Box position='relative' display='inline-flex'>
          <Avatar
            src={lootboxProfile ? lootboxProfile.avatar : ''}
            className='ádfsdfsd'
            style={{
              width: size,
              height: size,
              border: `${border}px solid`,
              borderColor: profile.mintable ? '#b5e8ae' : '#aebde8',
            }}
          />
          {editable && (
            <Box className={classes.editable}>
              <Upload accept='image/*' showUploadList={false} customRequest={handleChooseFile}>
                <Tooltip title='Update avatar'>
                  <IconButton className={classes.button} style={{ backgroundColor: color.alice }}>
                    <CameraAltOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Upload>
              <Dialog open={isOpenAvatar}>
                <UserAvatarCrop file={file} onSuccess={handleSuccess} onCancel={() => setIsOpenAvatar(false)} />
              </Dialog>
            </Box>
          )}
        </Box>
      </div>
      {editable && (
        <div className='row col-7 d-flex' style={{ textAlign: 'start', flexDirection: 'column' }}>
          <h1>{lootboxProfile && lootboxProfile.fullname}</h1>
          <h2>{lootboxProfile && shortenAddress(lootboxProfile.wallet_address, 4, 9)}</h2>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  editable: {
    position: 'absolute',
    right: 18,
    bottom: 18,
  },
  button: {
    padding: 6,
    border: '2px solid #fff',
  },
}));

export default UserAvatar;
