import { client } from './axios';

const order = (params) => client.get(`/order`, { params });
const checkout = (body) => client.post(`/order/checkout`, body);
const secondboxcheckout = (body) => client.post(`/secondsale/checkout`, body);
const cancelSecondsale = (id) => client.post('/cancelsecondsale/' + id);

export const checkoutService = {
  order,
  checkout,
  secondboxcheckout,
  cancelSecondsale,
};
