// import { client } from './axios';
import { client } from './axioNoErr';

const nonce = (params) => client.get(`/login/wallet`, { params });
const login = (body) => client.post(`/login/wallet`, body);
const register = (body) => client.post(`account/register/wallet`, body);
const getProfile = (params) => client.get(`/account/detail`, params);
const updateProfile = (body) => client.put(`/account/detail`, body);
const updateAccount = (body) => client.put('/account', body);
const presignurl = () => client.get(`/media/presign-upload`);

export const userServiceLb = {
  nonce,
  login,
  register,
  updateProfile,
  getProfile,
  updateAccount,
  presignurl
};
