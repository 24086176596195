import Web3 from 'web3';

import { abi as vender } from './abis/Vender.json';
import { abi as payment } from './abis/Payment.json';
import { abi as common } from './abis/common.json';
import { abi as spo } from './abis/Spo.json';
import { abi as usdt } from './abis/USDT.json';
import { abi as usdc } from './abis/USDC.json';
import { abi as busd } from './abis/BUSD.json';
const web3 = () => new Web3(window.ethereum);
export const venderContract = (address) => new (web3().eth.Contract)(vender, address);

export const paymentContract = (address) => new (web3().eth.Contract)(payment, address);
export const commonContract = (address) => new (web3().eth.Contract)(common, address);
export const spoContract = (address) => new (web3().eth.Contract)(spo, address);
export const usdtContract = (address) => new (web3().eth.Contract)(usdt, address);
export const usdcContract = (address) => new (web3().eth.Contract)(usdc, address);
export const busdContract = (address) => new (web3().eth.Contract)(busd, address);
