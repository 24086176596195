import ic_coin from './coin.png';
import ic_topSeller from './topseller.png';
import ic_arrowRight from './arrow-right.png';
import thumb_seller from './thumb-seller.png';
import thumb_coin from './avatar.png';
import ic_jackpot from './jackpot.png';
import ic_topSell from './ic-topSell.png';
import ic_explore from './explorer-icon.png';
import thumb_explore from './thumb-explore.png';
import fire_icon from './fire-icon.png';
import ic_filter from './ic_filter.png';
import ic_filterActive from './ic_filterActive.png';
import slider_1 from './slide-1.png';
import thumb_slider from './thumb-slider.png';
import slide_campain1 from './slide-campain1.png';
import slide_campain2 from './slide-campain2.png';
import slide_campaign_1 from './slideCampaign-1.png';
import slide_campaign_2 from './slideCampaign-2.png';
import slide_campaign_3 from './slideCampaign-3.png';
import slide_campaign_4 from './slideCampaign-4.png';
import banner_introduce from './banner-introduce.png';
import campaign_cover from './campaign_cover.png';
import rarity from './rarity.png';
import rarity1 from './rarity-1.png';
import rarity2 from './rarity-2.png';
import rarity3 from './rarity-3.png';
import rarity4 from './rarity-4.png';
import lootbox from './lootbox.png';
import lootbox_2 from './lootbox-2.png';
import line from './line.png';
import texttime from './texttime.png';
import item_drop1 from './item-drop1.png';
import item_drop from './item-drop.png';
import thumb_art from './art_thumb.png';
import image_artist from './image-artist.png';
import image_artist_1 from './artist/image-artist-1.jpg';
import image_artist_2 from './artist/image-artist-2.jpg';
import image_artist_3 from './artist/image-artist-3.jpg';
import image_artist_4 from './artist/image-artist-4.jpg';
import image_artist_5 from './artist/image-artist-5.jpg';
import image_artist_6 from './artist/image-artist-6.jpg';
import thumb_slider_new from './thumb_slider_new.png';
import ic_home_page from './homepage-ver-2-explorer-icon-2.png';
import legend from './l.png';
import super_rarity from './s.png';
import common from './c.png';
import rariryc from './r.png';
import epic from './e.png';
import s_1 from './s-1.png';
import s_2 from './s-2.png';
import s_3 from './s-3.png';
import s_4 from './s-4.png';
import s_5 from './s-5.png';
import s_6 from './s-6.png';
import s_7 from './s-7.png';
import s_8 from './s-8.png';
import no_image from './no-image-spo.jpg';

const artists = [image_artist_1, image_artist_2, image_artist_3, image_artist_4, image_artist_5, image_artist_6];

const fake_artists = {
  artist_1: {
    artist_name: 'leona devinci',
    artist_image: image_artist_1,
    artist_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
  },
  artist_2: {
    artist_name: 'Bui Xuan Phai',
    artist_image: image_artist_2,
    artist_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
  },
  artist_1: {
    artist_name: 'Tuong Lam',
    artist_image: image_artist_3,
    artist_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
  },
};

const fake_campaigns = [
  {
    name: 'Bored Ape Yacht Club',
    background_image: slide_campaign_1,
    campaign_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
    end_time: '2021-09-23T07:22:00',
  },
  {
    name: 'CrypToadz by GREMPLIN',
    background_image: slide_campaign_2,
    campaign_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
    end_time: '2021-10-05T07:22:00',
  },
  {
    name: 'CryptoPunks',
    background_image: slide_campaign_3,
    campaign_description:
      "Leonardo da Vinci[b] (15 April 1452 – 2 May 1519) was an Italian polymath of the High Renaissance who was active as a painter, draughtsman, engineer, scientist, theorist, sculptor and architect.[3] While his fame initially rested on his achievements as a painter, he also became known for his notebooks, in which he made drawings and notes on a variety of subjects, including anatomy, astronomy, botany, cartography, painting, and paleontology. Leonardo's genius epitomized the Renaissance humanist ideal,[4] and his collective works compose a contribution to later generations of artists matched only by that of his younger contemporary, Michelangelo.",
    end_time: '2021-10-23T07:22:00',
  },
];

const fake_timeline = {
  history: [
    {
      from_address: 'CuteCat123',
      image: thumb_coin,
      action: 'mint',
      time_stamp: '2021-09-23T07:22:00',
      price: 123,
    },
    {
      from_address: 'CuteCat123',
      image: thumb_coin,
      action: 'put on market',
      time_stamp: '2021-10-05T07:22:00',
      price: 123,
    },
    {
      from_address: 'Michcel',
      image: thumb_coin,
      action: 'bought',
      time_stamp: '2021-10-23T07:22:00',
      price: 123,
    },
  ],
};

const fake_owner = {
  history: [
    {
      from_address: 'CuteCat123',
      image: thumb_coin,
      edition: 6,
    },
    {
      from_address: 'CuteCat123',
      image: thumb_coin,
      edition: 6,
    },
    {
      from_address: 'Michcel',
      image: thumb_coin,
      edition: 6,
    },
  ],
};

const images = {
  ic_coin: ic_coin,
  ic_topSeller: ic_topSeller,
  ic_arrowRight: ic_arrowRight,
  thumb_seller: thumb_seller,
  thumb_coin: thumb_coin,
  ic_jackpot: ic_jackpot,
  ic_topSell: ic_topSell,
  ic_explore: ic_explore,
  thumb_explore: thumb_explore,
  fire_icon: fire_icon,
  ic_filter: ic_filter,
  ic_filterActive: ic_filterActive,
  slider_1: slider_1,
  thumb_slider: thumb_slider,
  slide_campain1: slide_campain1,
  slide_campain2: slide_campain2,
  banner_introduce: banner_introduce,
  rarity: rarity,
  rarity1: rarity1,
  rarity2: rarity2,
  rarity3: rarity3,
  rarity4: rarity4,
  lootbox: lootbox,
  line: line,
  texttime: texttime,
  item_drop1: item_drop1,
  item_drop: item_drop,
  thumb_art: thumb_art,
  image_artist: image_artist,
  thumb_slider_new: thumb_slider_new,
  image_homepagev2_explorer: ic_home_page,
  image_artist_array: artists,
  legend: legend,
  super_rarity: super_rarity,
  epic: epic,
  rariryc: rariryc,
  common: common,
  campaign_cover: campaign_cover,
  lootbox_2: lootbox_2,
  s_1: s_1,
  s_2: s_2,
  s_3: s_3,
  s_4: s_4,
  s_5: s_5,
  s_6: s_6,
  s_7: s_7,
  s_8: s_8,
  no_image: no_image,
};

export { images, artists, fake_campaigns, fake_artists, fake_timeline, fake_owner };
/*
export const images = {
  banner_introduce: './banner-introduce.png',
  rarity: './rarity.png',
  rarity1: './rarity.png',
  rarity2: './rarity.png',
  rarity3: './rarity.png',
  rarity4: './rarity.png',
  lootbox: './lootbox.png',
  line: './line.png',
  texttime: './texttime.png',
  item_drop1: './item-drop1.png',
  item_drop: './item-drop.png',
  ic_ratiry: './ic-rarity.png',
  thumb_art: './art_thumb.png',
};
};

export { images, artists };
*/
