import { Box, Typography, makeStyles } from '@material-ui/core';
import Countdown from 'react-countdown';

const CountdownTimer = ({ endTime }) => {
  const classes = useStyles();
  return (
    <Box className='flex-row'>
      <Countdown
        date={endTime * 1000}
        renderer={({ formatted: { days, hours, minutes, seconds } }) => (
          <>
            <Typography component='code' className={classes.box}>
              {days}
            </Typography>
            <Typography component='code' className={classes.box}>
              {hours}
            </Typography>
            <Typography component='code' className={classes.box}>
              {minutes}
            </Typography>
            <Typography component='code' className={classes.box}>
              {seconds}
            </Typography>
          </>
        )}
      />
    </Box>
  );
};

const Text = ({ endTime, onComplete }) => {
  return <Countdown date={endTime * 1000} onComplete={onComplete} />;
};

const Extra = ({ endTime, onComplete }) => {
  const classes = useStyles();
  return (
    <Box className='flex-row'>
      <Countdown
        date={endTime * 1000}
        onComplete={onComplete}
        renderer={({ formatted: { days, hours, minutes, seconds } }) => (
          <>
            <Box className={classes.extra}>
              <Typography variant='h5' component='code' className={classes.boxExtra}>
                {days}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Days
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' component='code' className={classes.boxExtra}>
                {hours}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Hours
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' component='code' className={classes.boxExtra}>
                {minutes}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Minutes
              </Typography>
            </Box>
            <Box className={classes.extra}>
              <Typography variant='h5' component='code' className={classes.boxExtra}>
                {seconds}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                Seconds
              </Typography>
            </Box>
          </>
        )}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  box: {
    borderRadius: 4,
    margin: '0px 1px',
    padding: '0px 4px',
    backgroundColor: '#232323',
    color: '#fff',
  },
  extra: {
    width: 80,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  boxExtra: {
    width: 52,
    height: 40,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#232323',
    color: '#fff',
    marginBottom: 8,
  },
}));

CountdownTimer.Text = Text;
CountdownTimer.Extra = Extra;

export default CountdownTimer;
