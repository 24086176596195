import React, { useEffect, useState } from 'react';
import coin from 'assets/images/coin.png';
import './checkout.scss';
import { useHistory } from 'react-router';
import { checkoutService } from 'services/lootbox/checkout';
import { GAS } from 'env';
import success from 'assets/images/success.png';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';
import { campaignService } from 'services/lootbox/campaign';
import {
  TableBody,
  Modal,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { Input } from 'antd';
import { spoContract, usdcContract, usdtContract, busdContract } from 'contracts/lootbox';
import { notification } from 'antd';
import { lbprofileAction } from 'actions/lootbox/profile';
import { getAddressBykey, handlePriceItem, shortenAddress } from 'utils/common';
import Web3 from 'web3';
const BigNumber = require('big-number');
const web3 = () => new Web3(window.ethereum);
const useStyles = makeStyles({
  table: {},
  boxCheckout: {
    display: 'flex',
    height: '100%',
  },
  checkoutModal: {
    '& .MuiDialog-paperWidthSm': {
      width: 905,
      // height: 530,
      maxWidth: 875,
      padding: '0 !important',
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },
  checkoutModal1: {
    '& .MuiDialog-paperWidthSm': {
      width: 600,
      maxWidth: 600,
      padding: '0 !important',
    },
    '& .MuiTableContainer-root': {
      overflowX: 'unset',
    },
  },

  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const CheckoutBox = (props) => {
  const classes = useStyles();
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const { className, campaign, priceShow, status, refresh } = props;

  const history = useHistory();

  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState(null);
  const [modalSucess, setModalSuccess] = useState(false);
  const [amount, setAmount] = useState(1);
  const { currencySystem } = useSelector(({ common }) => common);
  const [capchar, setCapchar] = useState(false);
  const [price, setPrice] = useState(null);
  const [disable, setDisable] = useState(false);
  const toggle = async () => {
    if (status == 'COMING SOON') {
      return;
    }
    if (!isLoggedIn) {
      lbprofileAction.connect();
      return;
    }
    setCapchar(false);
    setModal(!modal);
    // const { data: res } = checkoutService.order(id);
    // setOrder(res);
  };
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (campaign) {
      checkActive();
      if (priceShow == 'SPO') {
        setPrice(campaign.campaign_wave.first_price);
      } else {
        setPrice(campaign.campaign_wave.second_price);
      }
    }
  }, [priceShow, campaign]);
  const handlePrice = (price, number) => {
    let temp = parseFloat(price);
    for (let i = 0; i < number; i++) {
      temp = Number((temp * 10).toFixed(6));
      if (isInt(temp)) {
        let v = number - (i + 1);
        for (let j = 0; j < v; j++) {
          temp = temp + '0';
        }
        return temp;
      }
    }
  };

  const checkActive = () => {
    const nowDate = new Date().getTime();
    const endDate = new Date(campaign.campaign_wave.end_time).getTime();
    const startDate = new Date(campaign.campaign_wave.start_time).getTime();
    if (endDate < nowDate) {
      setDisable(true);
    }
  };

  function isInt(n) {
    return Number(n) === n && n % 1 === 0;
  }

  const checkInven=()=>{
    if(campaign){
      if ((campaign.campaign_wave.first_max_quantity - campaign.campaign_wave.first_sold_box_quantity) > 0) {
        return true
      } else if ((campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity) > 0) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if(campaign){
      checkActive()
    }
  }, [campaign]);

  const handleTranfer = async () => {
    // if (!capchar) {
    //   return
    // }
    const rest = await campaignService.userCampaign(campaign.campaign_wave.id)
    const currentCampaign = rest.data;
    const eventTime = moment(currentCampaign.campaign_wave.end_time).unix();
    const currentTime = moment().unix();
    
    if(eventTime <= currentTime){
      notification.error({ message: 'Campaign wave is not opened time' });
      return;
    }
    if(currentCampaign.campaign_wave.end_time)
    if (!amount || amount <= 0 || amount == '') {
      notification.error({ message: 'Amount invalid' });
      return;
    }
    if (
      currentCampaign &&
      currentCampaign.campaign_wave.allocation_per_user -
      currentCampaign.user_first_paid_number -
      currentCampaign.user_second_paid_number -
        amount <
        0
    ) {
      notification.error({ message: 'Exceeded quota' });
      return;
    }
    if (currentCampaign && currentCampaign.campaign_wave.max_box_quantity <= 0) {
      notification.error({ message: 'Box is out of stock' });
      return;
    }

    if (currentCampaign && currentCampaign.campaign_wave.max_box_quantity <= 0) {
      notification.error({ message: 'Box is out of stock' });
      return;
    }

    if (
      (currentCampaign &&
        currentCampaign.campaign_wave.first_max_quantity - amount - currentCampaign.campaign_wave.first_sold_box_quantity < 0 &&
        priceShow == 'SPO') ||
      (currentCampaign.campaign_wave.second_max_quantity - currentCampaign.campaign_wave.second_sold_box_quantity - amount < 0 &&
        priceShow != 'SPO')
    ) {
      notification.error({ message: 'Out of stock type paid currency' });
      return;
    }

    setLoading(true);
    const [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    let contract = null;

    switch (priceShow) {
      case 'SPO':
        contract = spoContract;
        break;
      case 'USDT':
        contract = usdtContract;
        break;
      case 'USDC':
        contract = usdcContract;
        break;
      case 'BUSD':
        contract = busdContract;
        break;
    }
    const currency = getAddressBykey(currencySystem, priceShow, 'adress');
    const decimal = getAddressBykey(currencySystem, priceShow, 'decimal');
    if (!contract || !currency || !decimal) {
      notification.error({ message: 'Currency incorrect' });
      return;
    }
    try {
      const res = await handleTranferWithCurrency(contract, currency, address, decimal);
      if (res == null) {
        return;
      }

      const body = {
        campaign_wave_id: currentCampaign.campaign_wave.id,
        payment_currency_address: currency,
        quantity: amount,
        transaction_hash: res.transactionHash,
      };

      const { data: checkoutRes } = await checkoutService.checkout(body);
      setModal(false);
      setModalSuccess(true);
      setLoading(false);
    } catch {
      refresh();
      setLoading(false);
    }
    refresh();
  };

  const handleTranferWithCurrency = async (contract, currency, address, decimal) => {
    const balance = await contract(currency).methods.balanceOf(address).call();
    const priceBuy = parseFloat(price) * amount;
    console.log("Current money:",balance)
    if (priceBuy > handlePriceItem(balance, decimal)) {
      notification.error({ message: 'Not enought money' });
      setLoading(false);
      return null;
    }
    return await contract(currency)
      .methods.transfer(
        campaign.campaign_wave.lootbox_campaign.check_out_wallet.address,
        
        handlePrice((parseFloat(price) * amount).toFixed(6), decimal),
      )
      .send({ from: address, gasLimit: GAS })
      .on('transactionHash', function (hash) {
        setLoading(true);
      })
      .on('error', function (error, receipt) {
        // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
        setLoading(false);
      });
  };

  function rand() {
    return Math.round(Math.random() * 20) - 10;
  }

  const handleAmount = (e) => {
    const temp = e.target.value;
    if (temp == null || temp == '' || parseInt(temp) < 1) {
      setAmount('');
      return;
    }
    if (
      campaign &&
      campaign.campaign_wave.first_max_quantity - parseInt(temp) - campaign.campaign_wave.first_sold_box_quantity <=
        0 &&
      priceShow == 'SPO'
    ) {
      setAmount(campaign.campaign_wave.first_max_quantity - campaign.campaign_wave.first_sold_box_quantity);
      return;
    }
    if (
      campaign &&
      campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity - parseInt(temp) <
        0 &&
      priceShow != 'SPO'
    ) {
      setAmount(campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity);
      return;
    }
    if (temp && parseInt(temp) > campaign.campaign_wave.allocation_per_user) {
      setAmount(campaign.campaign_wave.allocation_per_user);
      return;
    }

    if (parseInt(temp) > 0 && parseInt(temp) <= campaign.campaign_wave.allocation_per_user) {
      setAmount(parseInt(temp));
    }
  };

  const handleDisable = () => {
    if (disable || !checkInven()) {
      return true;
    }
    return false;
  };

  const handleSetChap = (e) => {
    if (e) {
      setCapchar(true);
    } else {
      setCapchar(false);
    }
  };

  function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }
  const handleOnclose = () => {
    return false;
  };
  const toggleSuccess = () => {
    setModalSuccess(!modalSucess);
  };
  return (
    <div className=''>
      <Button className='buy btn-mark-lg btn-buy-wave' onClick={toggle} disabled={handleDisable()}>
        {disable || !checkInven()? 'SOLD OUT':status}
      </Button>
      <Dialog
        className={classes.checkoutModal}
        onClose={() => handleOnclose()}
        aria-labelledby='customized-dialog-title'
        open={modal}
      >
        <DialogTitle
          className={classes.checkoutTitle}
          style={{ textAlign: 'center', borderBottom: 'none' }}
          id='customized-dialog-title'
          onClose={toggle}
        >
          {/* Checkout */}
        </DialogTitle>
        <DialogContent dividers style={{ borderTop: 'none', padding: '10px 20px', borderBottom: 'none' }}>
          <div className={classes.boxCheckout}>
            <div className='col-7'>
              <img src={campaign ? campaign.campaign_wave.lootbox_campaign.box_image : ''} width='100%' />
            </div>
            <div className='col-5 d-flex flex-column'>
              <div style={{ flex: '1'}}>
                <Typography variant='h4' style={{ marginLeft: '5px', fontWeight: 600, marginBottom: '10px' }}>
                  Checkout
                </Typography>
                <Typography style={{ marginLeft: '5px' }}>
                  You can buy a maximum of{' '}
                  {campaign &&
                    campaign.campaign_wave.allocation_per_user -
                      campaign.user_first_paid_number -
                      campaign.user_second_paid_number}{' '}
                  boxes.
                </Typography>
                <TableContainer style={{ overflowX: 'none' }}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ border: 'none', width: '35%' }}>Campaign</TableCell>
                        <TableCell style={{ border: 'none', width: '65%', fontWeight: 600 }} align='right'>
                          {campaign && campaign.campaign_wave.lootbox_campaign.title}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell style={{ border: 'none' }}>Price per box</TableCell>
                        <TableCell style={{ border: 'none', fontWeight: 600 }} align='right'>
                          {campaign && price} {priceShow}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }}>Number of box</TableCell>
                        <TableCell style={{ border: 'none', fontWeight: 600 }} align='right'>
                          <Input
                            defaultValue={amount}
                            value={amount}
                            style={{ width: 90 }}
                            onChange={(e) => {
                              handleAmount(e);
                            }}
                            type='number'
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ borderBottom: 'none' }} component='th' scope='row'>
                          Total
                        </TableCell>
                        <TableCell style={{ borderBottom: 'none', fontWeight: 700 }} align='right'>
                          {Number(campaign && parseFloat(parseFloat(price) * amount).toFixed(6))} {priceShow}
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell colSpan={2} style={{ borderBottom: 'none' }} component='th' scope='row'>
                          <Box style={{ textAlign: 'center', margin: '0px' }} className='row'>
                            <ReCAPTCHA
                              onChange={(e) => {
                                handleSetChap(e);
                              }}
                              className='recap'
                              sitekey='6Lf0i4EcAAAAAOYMw3gt-khTM16WHHwDTwbtINP2'
                            />
                          </Box>
                        </TableCell>
                      </TableRow>                     */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div style={{ padding: '5px', flex: '1' }} className="align-items-end">
                <Button
                  style={{ width: '100%', textTransform: 'uppercase' }}
                  autoFocus
                  onClick={handleTranfer}
                  disabled={loading}
                  className='btn-checkout btn-mark-lg row'
                  color='primary'
                >
                  CHECKOUT
                </Button>
                {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className='check6' style={{ borderTop: 'none', justifyContent: 'center' }}></DialogActions>
      </Dialog>

      <Dialog
        className={classes.checkoutModal1}
        onClose={modalSucess}
        aria-labelledby='customized-dialog-title'
        open={modalSucess}
      >
        <DialogContent dividers style={{ borderTop: 'none', padding: '10px 63px', borderBottom: 'none' }}>
          <div className='checkout-modal-success d-flex align-item-center'>
            <img width='83px' height='80px' src={success} alt='' />
            <div className='congratulation w-700'>Congratulations!</div>
            <div className='congrat-detail'>
              You have successfuly bought Loot Box{' '}
              <span style={{ color: '#D0B674' }}>{campaign && campaign.lootbox_name}</span>.
            </div>
            <div className='group-btn-checkout'>
              <Button onClick={() => history.push('/app/box-profile')} className='btn-lb btn-mark-wh'>
                Go to Profile
              </Button>
              <Button onClick={() => setModalSuccess(false)} className='btn-checkout btn-mark-lg'>
                Continue buying
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default CheckoutBox;
