import { client } from './axios';
import { client as client2 } from './axioNoErr';
import qs from 'qs';

const lootboxList = (params) => client.get(`/lootboxes`, { params });
const lootboxDetail = (id) => client2.get(`/lootbox/${id}`);
const openBox = (id) => client.put(`/lootboxopen/${id}`);
const resell = (id, body) => client.post(`/pushsecondsale/${id}`, body);
const detailresellItem = (id) => client2.get(`/onsell/${id}`);
const onsell = (body) => client.post('/onsell', body);
const secondSelaItem = (id) => client.get(`/secondsale/${id}`);

const marketBox = (params) => client.get('/secondsale', { params });
const marketItemQS = (params) => client.get('/onsell?' + params);
const marketBoxQS = (params) => client.get('/secondsale?' + params);
const marketItem = (params) => client.get('/onsell', { params });
const userNft = (params) => client.get(`/user_nft`, { params });
const userNftOne = (id) => client.get(`/user_nft/${id}`);
const ownerItem = (id)=> client.get("/user_nft_owners/"+id)

export const lootboxService = {
  lootboxList,
  lootboxDetail,
  openBox,
  resell,
  detailresellItem,
  onsell,
  secondSelaItem,
  marketBox,
  marketItem,
  userNft,
  userNftOne,
  marketItemQS,
  marketBoxQS,
  ownerItem
};
