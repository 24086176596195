import React from 'react';
import { Box, Button, Slider } from '@material-ui/core';
import { AvatarEditor, ColorButton, Loading } from 'components';
import { userService } from 'services/user';
import { userServiceLb } from 'services/lootbox/user';
import axios from 'axios'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { notification } from 'antd';

const UserAvatarCrop = ({ file, onSuccess, onCancel }) => {
  const inputEl = React.useRef();
  const [scale, setScale] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const image = React.useMemo(
    () => ({
      width: innerWidth > 536 ? 470 : innerWidth - 64,
      height: innerWidth > 536 ? 470 : innerWidth - 64,
      border: 1,
      borderRadius: 235,
    }),
    [innerWidth],
  );

  const handleClickSave = async () => {
    if (inputEl) {
      const result = await userServiceLb.presignurl()

      await axios.put(result.data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
        },
      });

      const [imageUrl] = result.data.url.split('?');
      const formData = new FormData();
      formData.append('avatar', imageUrl);
      setIsLoading(true);
      await userServiceLb.updateAccount(formData);
      notification.success({ message: 'upload image success' });
      setIsLoading(false);
    }
  };
  const handleSubmit = async () => {
    await handleClickSave();
    onSuccess();
  };

  return (
    <Box>
      <Box className='mb-12'>
        <AvatarEditor ref={inputEl} image={file} scale={scale} {...image} />
        <Slider defaultValue={1} step={0.001} min={1} max={2} onChange={(_, value) => setScale(value)} />
      </Box>
      <Box className='justify-content-end Button-Line'>
        <Button variant='outlined' onClick={onCancel}>
          Cancel
        </Button>
        <ColorButton
          variant='outlined'
          disabled={isLoading}
          startIcon={<Loading visible={isLoading} icon={<CheckOutlinedIcon />} />}
          onClick={handleSubmit}
        >
          Update
        </ColorButton>
      </Box>
    </Box>
  );
};

export default UserAvatarCrop;
