import { notification } from 'antd';
import { lbprofileAction } from 'actions/lootbox/profile';
import { store } from 'reducers';
// import { NETWORK_MAPS } from 'components/NetworkBar';
import { API_URI_LOOTBOX } from 'env';
import { stringify } from 'query-string';
import axios from 'axios';

const onError = ({ response }) => {
  if (response) {
    const { data, status, statusText } = response;
    if (status === 401) {
      lbprofileAction.signOut();
    } else {
      //   const { message } = data;
      //   notification.error({ message: message ?? `${status} - ${statusText}` });
    }
  } else {
    notification.error({ message: `Cannot connect to Server` });
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  // config.url = (NETWORK_MAPS[window.ethereum?.chainId] ?? '') + config.url;
  const { isLoggedIn, access_token } = store.getState().profileLootbox;
  if (isLoggedIn)
    Object.assign(config.headers, {
      Authorization: `Bearer ${access_token}`,
    });
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URI_LOOTBOX,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
client.interceptors.request.use(beforeRequest);

const clientRaw = axios.create({
  baseURL: API_URI_LOOTBOX,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});

[client, clientRaw].forEach((client) => {
  client.interceptors.response.use(({ data }) => data, onError);
});

export { client, clientRaw };
