export const ActionType = {
  CURRENCY_SYSTEM: 'CURRENCY_SYSTEM',
  SHOW_BOX_ITEM: 'SHOW_BOX_ITEM',
};
const initState = {
  boxItem: null,
};

const CURRENCY_SYSTEM = (state = { ...initState }, { type, data }) => {
  switch (type) {
    case ActionType.CURRENCY_SYSTEM:
      return { ...state, currencySystem: data };
    case ActionType.SHOW_BOX_ITEM:
      return { ...state, boxItem: data };
    default:
      return state;
  }
};

export default CURRENCY_SYSTEM;
