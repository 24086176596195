import React, { Fragment, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './boxDetail.scss';
import { Button, Card, notification } from 'antd';

import CheckoutModal from '../checkout/Checkout';
import avatar from '../../assets/images/person.png';
import shape from '../../assets/images/shape.png';
import coin from '../../assets/images/coin.png';
import follow from '../../assets/images/follow.png';
import { API_URI_LOOTBOX } from 'env';
import { authorService } from 'services/lootbox/authorService';
import boximage from 'assets/images/lootbox-3.png';
import { commonService } from 'services/lootbox/commonService';
import moment from 'moment';
import { shortenAddress, shorterName } from 'utils/common';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { checkoutService } from 'services/lootbox/checkout';
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
export default function BoxInfor(props) {
  const { lootbox } = props;

  const [loading, setLoading] = useState(false);
  const [serialConten, setSerialContent] = useState(null);
  const [totalLike, setTotalLike] = useState(0);
    const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const classes = useStyles();
  const history = useHistory();
  const [durationDiff, setDurationDiff] = useState(null);
  useEffect(() => {
    if (lootbox) {
      countDownTime();
    }
  }, [lootbox]);
  function countDownTime() {
    const eventTime = moment(lootbox.end_time).unix();
    const currentTime = moment().unix();
    let diffTime = eventTime - currentTime;
    if (diffTime >= 0) {
      let ress = moment.duration(diffTime) * 1000;
      setInterval(function () {
        ress = ress - 1000;
        if (ress >= 0) {
          setDurationDiff(ress);
        } else {
          setDurationDiff(0);
        }
      }, 1000);
    }
  }
  const handleCancelResell = async () => {
    let res = null;
    try {
      setLoading(true);
      res = await checkoutService.cancelSecondsale(lootbox.Lootbox.id);
    } catch (err) {
      setLoading(false);
      history.push('/app/market');
      return;
    }
    notification.success({ message: 'Cancel resell box success' });
    setLoading(false);
    history.push('/app/market');
  };
  const genCurrency = (value) => {
    if (lootbox) {
      switch (lootbox.Lootbox.currency_id) {
        case 1:
          return 'SPO';
        case 2:
          return 'USDT';
        case 3:
          return 'USDC';
        case 4:
          return 'BUSD';
      }
    }
  };
  
  return (
    <Fragment>
      <div className='row detail-box'>
        <div className='col-md-5 pl-0 col-xs-12 image-detail-box'>
          <div className='flex-center image-box image-contain p-24' style={{ height: '100%', width: '100%' }}>
            <img src={lootbox?.LootboxCampaign?.box_image} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        </div>
        <div className='col-md-7 col-xs-12 infor-product d-flex flex-column check2'>
          <div className='col-12 p-0'>
            <div className='name-product p-0'>
              <span>
                {lootbox && lootbox.LootboxCampaign.title} #{lootbox && lootbox.Lootbox.id}
              </span>
            </div>
            <div className='description-title'>Description</div>
            <div className='content-product'>
              <div className='author-infor'>
                <div className='author'>
                  <img
                    src={
                      lootbox && lootbox.Lootbox.user.avatar && lootbox.Lootbox.user.avatar != ''
                        ? lootbox.Lootbox.user.avatar
                        : avatar
                    }
                    alt=''
                    width='24'
                    className='avattar'
                  />
                  <span>Owner by</span>{' '}
                  <span style={{ fontWeight: 700, marginLeft: 3 }}>
                    {' '}
                    {lootbox && shorterName(lootbox.Lootbox.user.fullname)}
                  </span>
                </div>
                <CopyToClipboard className='CopyToClipboard' text={lootboxProfile?.wallet_address}>
                  <div>
                    <span>{lootboxProfile && shortenAddress(lootboxProfile.wallet_address, 8, 8)}</span>
                    <img src={shape} alt='Copy to clipboard' />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className='description'>{lootbox && lootbox.LootboxCampaign.content}</div>
          </div>

          <div className='detail-footer d-flex align-items-end'>
            <div className='price-product'>
              <div className='price-txt'>Price</div>
              <div className='price-value d-flex'>
                <span className='price-number'>
                  {lootbox && lootbox.Lootbox.resell_price} {genCurrency()}
                </span>
              </div>
            </div>
                    {((lootboxProfile && lootbox && lootboxProfile.id != lootbox.Lootbox.owner) || !isLoggedIn) && <CheckoutModal lootbox={lootbox}></CheckoutModal> }

            {lootboxProfile && lootbox && lootboxProfile.id == lootbox.Lootbox.owner && (
              <a
                onClick={() => {
                  handleCancelResell();
                }}
                className='a-button a-button-color a-bg-color-yellow col-6'
              >
                {!loading ? 'Cancel' : <CircularProgress className={classes.buttonProgress} size={24} />}
              </a>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
