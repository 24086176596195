import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { color } from 'utils/constants';

export const appTheme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      InputLabelProps: { shrink: true },
      inputProps: { autoSave: 'false' },
    },
    MuiChip: {
      variant: 'outlined',
    },
    MuiTypography: {
      component: 'div',
    },
    MuiInputBase: {
      style: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      disableElevation: true,
      style: {
        textTransform: 'none',
      },
    },
    MuiContainer: {
      maxWidth: 'xl',
    },
  },
  typography: {
    fontFamily: 'Segoe UI Variable',
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
    h1: { fontWeight: 700 },
    h2: { fontWeight: 700 },
    h3: { fontWeight: 700 },
    h4: { fontWeight: 700 },
    h5: { fontWeight: 700 },
    h6: { fontWeight: 700 },
    subtitle1: { fontWeight: 600, lineHeight: 1.5 },
    subtitle2: { fontWeight: 600, lineHeight: 1.43 },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.725rem',
      },
    },
    MuiContainer: {
      maxWidthXl: {
        maxWidth: '1440px !important',
      },
    },
    MuiButton: {
      sizeLarge: {
        height: 64,
        fontSize: 20,
      },
    },
    MuiListItem: {
      button: {
        fontWeight: 600,
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: color.black,
      },
      flexContainer: {
        borderBottom: '1px solid #0001',
      },
    },
    MuiTab: {
      root: {
        fontSize: '1rem',
      },
    },
  },
  palette: {
    primary: {
      main: color.primary,
    },
    secondary: {
      main: color.secondary,
    },
  },
});

const Theme = ({ children }) => {
  return <ThemeProvider theme={appTheme}>{children}</ThemeProvider>;
};

export default Theme;
