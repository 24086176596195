import Ellipse from 'assets/images/Ellipse-23.png';
import vector1 from 'assets/images/Vector1.png';
import { FILES_ACCEPT_AVATAR } from 'constants/common';
import React, { Fragment, PureComponent } from 'react';
import './boxProfile.scss';

class ImageProfileSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
    };

    this.refFile = React.createRef();

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  onMouseMove() {
    this.setState(() => {
      return { isHover: true };
    });
  }

  onMouseLeave() {
    this.setState(() => {
      return { isHover: false };
    });
  }

  handleUpload() {
    this.refFile.current.click();
  }

  handleFile(e) {
    const files = e.target.files;

    const file = files[0];
  }

  render() {
    return (
      <Fragment>
        <input
          ref={this.refFile}
          type='file'
          hidden
          onChange={this.handleFile}
          accept={FILES_ACCEPT_AVATAR.join(',')}
        />
        <div className='image-select' onMouseMove={this.onMouseMove} onMouseLeave={this.onMouseLeave}>
          {this.state.isHover && (
            <div className='bottom' onClick={this.handleUpload}>
              <img src={vector1} width='30px' alt='' className='camera-icon' />
              <span>Change your avatar</span>
            </div>
          )}
          <div className='circle-boder'>
            <div className='circle-boder-2'>
              <img src={Ellipse} alt='' />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ImageProfileSelect;
