import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { AppHeader, AppFooter, AppFeedback } from 'containers';
import { systemAction } from 'actions/system';
import { profileAction } from 'actions/profile';
import { systemService } from 'services/system';
import { privateRoute } from 'routes';
import { jwtDecode } from 'utils/common';
import { lbprofileAction } from 'actions/lootbox/profile';
import { useHistory } from 'react-router';
import { commonAction } from 'actions/lootbox/common';
import { store } from 'reducers';
import moment from 'moment';

const PrivateLayout = () => {
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);

  const [isReady, setIsReady] = React.useState(false);

  const history = useHistory();
  const hanldeLogout = () => {
    lbprofileAction.signOut();
    history.push('/campain');
  };

  React.useEffect(() => {
    window.ethereum?.on('accountsChanged', hanldeLogout);
  }, []);

  React.useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile_lootbox'));
      // const payload = jwtDecode(profile.access_token);
      const expireTime = moment(profile.expire).unix();
      const currentTime = moment().unix();
      if (expireTime - 3600 < currentTime) {
        hanldeLogout();
      }
    } catch {}
  }, []);

  React.useEffect(() => {
    const { isLoggedIn: login, access_token } = store.getState().profileLootbox;
    commonAction.getCurrency();
    if (login) {
      lbprofileAction.fetchProfile();
    }
  }, [isLoggedIn]);

  return (
    <div className='App Private-Layout'>
      <AppHeader />
      <AppFeedback />
      <div className='App-Body'>
        <Switch>
          {Object.values(privateRoute)
            .filter(({ requiredLogin }) => !requiredLogin || isLoggedIn)
            .map(({ path, component }) => (
              <Route exact key={path} path={path} component={component} />
            ))}
          <Redirect from='/' to={privateRoute.campain.path} />
        </Switch>
      </div>
      <AppFooter />
    </div>
  );
};

export default PrivateLayout;
