import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import BoxImage from './BoxImage';

const HistoryTimeline = ({ items = [] }) => {
  const classes = useStyles();

  return (
    <Timeline classes={{ root: classes.root }}>
      {items.map((item, index) => (
        <TimelineItem key={item.id} classes={{ missingOppositeContent: classes.missingOppositeContent }}>
          <TimelineSeparator style={{ marginTop: 16, marginBottom: index < items.length - 1 ? -16 : 4 }}>
            <TimelineDot variant='outlined' color='primary'>
              <Divider className={classes.divider} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <BoxImage
              reverse
              image={item.image}
              label={item.description}
              author={
                <div className='justify-content-between'>
                  <span>{item.title}</span>
                  <span>{item.price}</span>
                </div>
              }
              size={44}
            />
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

const useStyles = makeStyles({
  root: {
    padding: '6px 0px',
  },
  missingOppositeContent: {
    '&::before': {
      display: 'none',
    },
  },

  divider: {
    position: 'absolute',
    left: 16,
    height: 2,
    width: 6,
    backgroundColor: '#bdbdbd',
  },
});

export default HistoryTimeline;
