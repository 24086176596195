import robot from 'assets/images/robot.png';
import { Card, CardBody, CardImg, CardText } from 'reactstrap';
import './boxProfile.scss';
import lootbox from 'assets/images/lootbox-3.png';
import tt from 'assets/images/tt.png';
import opened from 'assets/images/opened.png';
import avatar from 'assets/images/person.png';
import { API_URI_LOOTBOX } from 'env';
import moment from 'moment';
import { Button } from 'antd';
import { useSelector } from 'react-redux';
import { getAddressById, getAddressBykey, handlePriceItem, shorterName } from 'utils/common';
import { Fragment } from 'react';

export default function DropItem(props) {
  const { item, type } = props;

  const image = item.image === '<nil>' ? undefined : item.image;
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const { currencySystem } = useSelector(({ common }) => common);

  // const calDay=()=>{
  //     const date = new Date();
  //     a.diff(, 'days')
  // }
  //API_URI_LOOTBOX + item.CampaignWave.LootboxCampaign.box_image

  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Super Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    // if (name == "Rare 2") {
    //     return images.rarity4;
    // }
    return 'rarity_btn rarity_common';
  };
  const price =
    item && item.type === 'box'
      ? item.resell_price
      : handlePriceItem(item.price + '', getAddressBykey(currencySystem, item.currency_label, 'decimal')) *
        item.quantity;

  const gendImage = () => {
    if (item) {
      if (item.type == 'box') {
        return item.CampaignWave.LootboxCampaign.box_image;
        // return lootbox
      }
      if (item.type == 'item') {
        return item.image;
      }
      if (item.type == 'open') {
        return item.CampaignWave.LootboxCampaign.box_image;
      }
    }
  };

  const diffDate = (endtime) => {
    const date1 = moment(new Date()).unix();
    const date2 = moment(endtime).unix();
    const diffTime = (date2 - date1)*1000;
    if (diffTime > 0) {
      const day = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[0];
      const hour = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[1];
      const minute = moment.duration(diffTime).format('DD:hh:mm:ss', { trim: false }).split(':')[2];
      //console.log('hello ',day);
      if (day && day != '00') {
        return day + (parseInt(day) > 1 ? ' days left to open' : ' day left to open');
      }
      if (hour && hour != '00') {
        return hour + (parseInt(hour) > 1 ? ' hours left to open' : ' hour left to open');
      }
      if (minute && minute != '00' && minute != '01') {
        return minute + (parseInt(minute) > 1 ? ' minutes left to open' : ' minute left to open');
      }
    }
    return '';
  };
  const item_count = (item && type == 'item') || (type == 'all' && item.type == 'item') ? item.amount_nft - item.amount_onsell : type == 'onsell' && item.quantity ? item.quantity : 0;
  const item_edition_string = (item_count > 1 && item_count != 0) ? `${item_count} editions` :  `${item_count} edition`;  
  return (
    <Fragment>
      {item && (
        <div className='col-12 p-0'>
          <Card className='card-item pt-3 pb-3' hoverable>
            {item && item.type == 'box' && (
              <p style={{ minHeight: 29 }}>{diffDate(item.CampaignWave.LootboxCampaign.open_end_time)}</p>
            )}
            {item && item.type == 'item' && (
              <p>
                <div className='d-flex' style={{ justifyContent: 'space-between' }}>
                  <h5 className={`drop-item-rarity ` + genClass(item.nft_types)}>{item && item.nft_types}</h5>
                  <div>
                    <h6 className="drop-amount-item check30">{item_edition_string}</h6>
                    {/*{(item && type == 'item') || (type == 'all' && item.type == 'item')
                      ? item.amount_nft - item.amount_onsell
                      : type == 'onsell' && item.quantity
                      ? item.quantity
                      : 0}*/}
                  </div>
                </div>
              </p>
            )}
            {/*<div className="image-div"><CardImg top src={gendImage()} alt='Card image cap' /></div>*/}
            <div className='square-image'>
              <div
                className='image'
                style={{ backgroundImage: `url( ${gendImage()})`, height: '100%', width: '100%' }}
              ></div>
            </div>

            <CardBody style={{ padding: '0px 5px' }}>
              <CardText className='pt-3'>
                {/*{item && item.type == "box" && <span>{item && item.campaign_title} #{item && item.id}</span>}*/}
                {/*{item && item.type == "box" && <span>{item && item.campaign_title} #{item && item.id}</span>}*/}
                {item && item.type == 'box' && (
                  <span style={{ fontWeight: '600' }}>
                    {item && item.CampaignWave.LootboxCampaign.title + ' #' + item.id}
                  </span>
                )}
                {item && item.type == 'item' && <span style={{ fontWeight: '600' }}>{item && item.name}</span>}
              </CardText>
              <CardText className='avatar-c d-flex align-items-center'>
                {type && type != 'open'? <Fragment>
                  
                <img
                  src={lootboxProfile && lootboxProfile.avatar ? lootboxProfile.avatar : avatar}
                  width='15px'
                  height='15px'
                  alt=''
                  style={{ marginRight: 5, borderRadius: '50%' }}
                />
                {lootboxProfile && shorterName(lootboxProfile.fullname)}
                  </Fragment>:
                    <div className="item-name" style={{ fontWeight: '600' }}>
                    {item && item.CampaignWave.LootboxCampaign.title + ' #' + item.id}
                  </div>
                  }
                
              </CardText>
              {type && type == 'onsell' && (
                <CardText className='pice-div'>
                  <span className='price-title'>Price</span>
                  <span className='price' style={{ fontWeight: 'bold' }}>
                    {price}{' '}
                    {item && item.type == 'item'
                      ? item.currency_label
                      : item.type == 'box'
                      ? getAddressById(currencySystem, item.currency_id, 'label')
                      : ''}{' '}
                  </span>
                </CardText>
              )}
            </CardBody>
          </Card>
        </div>
      )}
    </Fragment>
  );
}
