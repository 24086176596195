export const ActionType = {
  GET_CAMPAIGN_LOOTBOX: 'GET_CAMPAIGN_LOOTBOX',
  GET_CAMPAIGNS_LOOTBOX: 'GET_CAMPAIGNS_LOOTBOX',
  GET_CAMPAIGNS_WALLET: 'GET_CAMPAIGNS_WALLET',
  GET_CAMPAIGN_WALLET: 'GET_CAMPAIGN_WALLET',
  CAMPAIGNS_LOOTBOX_UPDATE: 'CAMPAIGNS_LOOTBOX_UPDATE',
  RESELL_LOOTBOX: 'RESELL_LOOTBOX',
};

const campaign = (state = {}, { type, data }) => {
  switch (type) {
    case ActionType.GET_CAMPAIGN_LOOTBOX:
      return { data };
    case ActionType.GET_CAMPAIGNS_LOOTBOX:
      return { data };
    case ActionType.GET_CAMPAIGN_WALLET:
      return { data };
    case ActionType.GET_CAMPAIGNS_WALLET:
      return { data };
    case ActionType.CAMPAIGNS_LOOTBOX_UPDATE:
      return { data };
    case ActionType.RESELL_LOOTBOX:
      return { data };
    default:
      return state;
  }
};

export default campaign;
