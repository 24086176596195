export const normalizeItem = (item, { address: user } = {}) => {
  const ownership = item.ownerships?.[0] ?? {};
  const sales = ownership.sales?.filter((item) => item.status === 0) ?? [];
  const isErc1155 = item.nftType === 1;
  const isListedOnMarket = sales.length > 0;

  const image = item.media?.[0]?.url;
  const preview = item.media?.[1]?.url;
  const isVideo = item.media?.length > 1;

  const creator = item.creator || {};
  const owner = ownership.owner || creator;
  const saleInfo = isListedOnMarket ? sales[0] : {};

  const isFixedPrice = saleInfo.saleType === 0;
  const isAuction = saleInfo.saleType === 1;

  const currentTime = Date.now() / 1000;
  const isAuctionStarted = saleInfo.startTime < currentTime;
  const isAuctionFinished = saleInfo.endTime < currentTime;

  const isUserOwner = user ? user === owner.address : false;

  return Object.assign(item, {
    isErc1155,
    isListedOnMarket,
    isFixedPrice,

    isAuction,
    isAuctionStarted,
    isAuctionFinished,

    image,
    preview,
    isVideo,

    ownership,
    ownershipID: ownership.id,
    creator,
    owner,
    saleInfo,
    collection: normalizeCollection(item.subCollection),

    isUserOwner,
  });
};

export const normalizeCollection = (item = {}) => {
  if (item.id)
    return {
      ...item.collection,
      ...item,
    };
  else {
    return {
      image: '/assets/icons/logo-circle.png',
      name: 'Spores',
      symbol: 'SPO',
    };
  }
};
