import { store } from 'reducers';
import { ActionType } from 'reducers/loobox/common';
import { commonService } from 'services/lootbox/commonService';

const getCurrency = async () => {
  const { data } = await commonService.currencyList();
  store.dispatch({
    type: ActionType.CURRENCY_SYSTEM,
    data: data,
  });
};

const setItems = (data) => {
  store.dispatch({
    type: ActionType.SHOW_BOX_ITEM,
    data: data,
  });
};

export const commonAction = {
  getCurrency,
  setItems,
};
