export const ActionType = {
  ORDER: 'ORDER',
  CHECKOUT: 'CHECKOUT',
};

const checkout = (state = {}, { type, data }) => {
  switch (type) {
    case ActionType.ORDER:
      return { data };
    case ActionType.CHECKOUT:
      return { data };
    default:
      return state;
  }
};

export default checkout;
