import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Container, Typography, Paper, FormGroup, TextField, Button } from '@material-ui/core';
import { Col, notification, Row } from 'antd';
import { ColorButton, Loading } from 'components';
import { profileAction } from 'actions/profile';
import { userService } from 'services/user';
import { userServiceLb } from 'services/lootbox/user';
import { privateRoute } from 'routes';
import { UserAvatar } from './components';

import CheckOutlined from '@material-ui/icons/CheckOutlined';
import { lbprofileAction } from 'actions/lootbox/profile';

const ProfileUpdate = () => {
  const history = useHistory();

  const { control, handleSubmit, reset } = useForm({ mode: 'onChange', defaultValues: account });

  const [isLoading, setIsLoading] = React.useState(false);
  const [account, setAccount] = React.useState();
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const handleClickUpdate = () => {
    handleSubmit(async ({ username, bio }) => {
      const formData = new FormData();
      const json = {
        fullname: username,
        profile: bio,
      };

      setIsLoading(true);
      const res = await userServiceLb.updateAccount(json);
      if (res) {
        notification.success({ message: 'update profile success' });
      }
      lbprofileAction.fetchProfile();
      setIsLoading(false);
    })();
  };

  return (
    <Container maxWidth='md'>
      <Paper className='p-24 profile-update-page'>
        <Row gutter={24}>
          <Col md={24} className='text-align-center pt-20'>
            <UserAvatar account={account} editable />
          </Col>

          <Col md={24}>
            <Typography variant='h4' gutterBottom>
              Update Profile
            </Typography>
            {lootboxProfile && (
              <FormGroup>
                <Controller
                  name='username'
                  defaultValue={lootboxProfile.fullname}
                  control={control}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField {...field} required label='Name' error={invalid} helperText={error?.message} />
                  )}
                />

                <Controller
                  name='bio'
                  defaultValue={lootboxProfile.profile}
                  control={control}
                  rules={{
                    validate: {
                      maxLength: (value) => value.trim().length <= 160 || 'Biography is at most 160 characters',
                    },
                  }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      multiline
                      rows={5}
                      label='Biography'
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </FormGroup>
            )}

            <Button
              variant='outlined'
              disabled={isLoading}
              onClick={handleClickUpdate}
              style={{ backgroundColor: '#cfb675', color: '#FFFFFF', marginRight: '10px' }}
            >
              Update
            </Button>
            <Button variant='outlined' disabled={isLoading} onClick={() => history.push('/app/box-profile')}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Paper>
    </Container>
  );
};

export default ProfileUpdate;
