/* eslint-disable no-unreachable */
import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { images } from 'assets/images/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Player from 'react-player'
// import { Button } from 'antd';
import { API_URI_LOOTBOX } from 'env';

function PopupDetail(props) {
  const {isShowImages,setIsShowImages,isPopup,setIsPopup, item, campaign, setItemDetail, dropItem, arrayTotal} = props;
  const [setting, setSetting] = useState(false);
  const wrapperRef = useRef(null);
  const wrapperRefPupup = useRef(null);
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 7,
  //   slidesToScroll: 3,
  //   initialSlide: 0,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  const settings = {
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 5,
        },
      },
    ],
  };
  const arr = [
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
    `${images.item_drop}`,
    `${images.item_drop1}`,
  ];
  const getImage = (name) => {
    switch (name) {
      case 'Legandary':
        return images.rarity;
        break;
      case 'Epic':
        return images.rarity1;
        break;
      case 'Super Rare':
        return images.rarity2;
        break;
      case 'Rare':
        return images.rarity3;
        break;
      case 'Common':
        return images.rarity4;
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    setSetting({ ...settings, slidesToShow: 2 });
  }, [item]);
  //console.log("showImage:",isShowImages)
  const genClass = () => {
    if (item) {
      const name = (item.nft_type?.label || '').toUpperCase();
      if (name === 'LEGENDARY') {
        return 'rarity_btn rarity_legend';
      }
      if (name === 'EPIC') {
        return 'rarity_btn rarity_epic';
      }
      if (name === 'SUPER RARE') {
        return 'rarity_btn rarity_super_rare';
      }
      if (name === 'RARE') {
        return 'rarity_btn rarity_rare';
      }
    }

    // if (name == "Rare 2") {
    //     return images.rarity4;
    // }
    return 'rarity_btn rarity_common';
  };

  //handle click outside
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [isShowImages]);
  useEffect(() => {
    document.addEventListener("click", handleClickOutsideModal, true);
    return () => {
      document.removeEventListener("click", handleClickOutsideModal, true);
    };
  }, [isPopup]);

  const handleClickOutside = event => {
    if (isShowImages && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsShowImages(false);
    }
  };

  const handleClickOutsideModal = event => {
    if (!isShowImages && wrapperRefPupup.current && !wrapperRefPupup.current.contains(event.target)) {
      setIsPopup(false);
    }
  };

  const getProbability = () => {
    if (item && dropItem) {
      console.log('dropItem ====>', dropItem)
      const filter = dropItem.filter((f) => f.nft_type?.label == item.nft_type?.label);
      if (filter && filter.length > 0) {
        return (filter[0].total_supply / arrayTotal.totalSupply) * 100;
      }
    }
    return 0
  };

  // console.log('item====>', item)
  return (
    <div className='wrap' style={{ backgroundColor: 'transparent' }} >
      {isShowImages ? (
        <div className="d-flex"  ref={wrapperRef}>
          <img src={props.item.image} style={{ maxWidth: '100%' }} />
        </div>
      ) : (
        <div className='container' style={{ backgroundColor: 'white' }} ref={wrapperRefPupup}>
          <div className='info' style={{ minHeight: campaign.campaign_wave.lootbox_campaign.nfts.length > 1 ? 'auto' : '100%' }}>
            <div className='avatar'>
              { item.video
                ? <Player width='400px' url={item.video} controls/>
                : <a onClick={() => setIsShowImages(true)}><img src={item.image}  /></a>
              }
              
            </div>
            <div className='profile'>
              <div className='title'>{item.name}</div>
              <div className='value'>
                <div className='caption'>Campaign: </div>
                <div className='name-campain'>{campaign && campaign.campaign_wave.lootbox_campaign.title}</div>
              </div>
              <div className='value'>
                <div className='caption'>Artist: </div>
                <div className='bold'>{campaign && campaign.campaign_wave.lootbox_campaign.artist_name}</div>
              </div>
              <div className='value align-items-center'>
                <div className='caption'>Rarity:</div>{' '}
                <h5 style={{ margin: '0px' }} className={`drop-item-rarity ` + genClass()}>
                  {item && item.nft_type?.label}
                </h5>
              </div>
              <div className='value'>
                Amount: <div className='bold'>{item && item.total_supply}</div>
              </div>
              <div className='value'>
                Probability: <div className='bold'>{getProbability().toFixed(2)} %</div>
              </div>
              <div style={{ marginTop: 15 }} className='desc'>
                Description
              </div>
              <div className='value'>{item && item.description}</div>
            </div>
          </div>
          {campaign && campaign.campaign_wave.lootbox_campaign.nfts?.length > 4 && (
            <div className='list-img'>
              <div className='title-list'>Within this Campaign</div>
              <div className='list'>
                <Slider {...setting}>
                  {campaign.campaign_wave.lootbox_campaign.nfts?.map((item, index) => (
                    <div key={index} className="item-div"><img
                      onClick={() => setItemDetail(item)}
                      src={item.image}
                      key={index}
                      className='img-slide'
                    /></div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
          {campaign && campaign.campaign_wave.lootbox_campaign.nfts?.length <= 4 && campaign.campaign_wave.lootbox_campaign.nfts?.length > 1 && (
            <div className='list-img amount_1_4'>
              <div className='title-list'>Within this Campaign</div>
              <div className='list'>
                  {campaign.campaign_wave.lootbox_campaign.nfts?.map((item, index) => (
                    <div key={index} className="amount_1_4-item-div"><img
                      onClick={() => setItemDetail(item)}
                      src={item.image}
                      key={index}
                      className='img-slide'
                    /></div>
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
export default PopupDetail;
