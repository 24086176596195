import React, { Fragment, useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './boxDetail.scss';
import { Button, Space, Select, Card, notification } from 'antd';

import CheckoutModal from '../checkout/Checkout';
import avatar from '../../assets/images/person.png';
//import PersonIcon from '@material-ui/icons/Person';

//import avatar from '@material-ui/icons/Person';
import lootbox from '../../assets/images/lootbox-3.png';
import coin from '../../assets/images/coin.png';
import follow from '../../assets/images/follow.png';
import shape from '../../assets/images/shape.png';
import Legend from '../open/Legend';
import PutOnSaleBoxModal from 'components/put-on-sale/PutOnSaleBoxModel';
import { API_URI_LOOTBOX } from 'env';
import { lootboxService } from 'services/lootbox/lootbox';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { checkoutService } from 'services/lootbox/checkout';
import { shortenAddress, shorterName } from 'utils/common';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
const useStyles = makeStyles({
  buttonProgress: {
    color: blue[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
const BoxDetailOpen = (props) => {
  const classes = useStyles();
  const { idBox, commissionFee } = props;
  const [show, setShow] = useState(false);
  const { campaign, refresh } = props;
  const [openDetail, setOpenDetail] = useState(null);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setShow(false);
  }, []);
  const handleOpen = async () => {
    setLoading(true);
    setShow(true);
    let res = null;
    try {
      await lootboxService.lootboxDetail(campaign.Lootbox.id);
    } catch (error) {
      history.push('/app/box-profile');
      setLoading(false);
      setShow(false);
      notification.error({ message: 'lootbox not found' });
      return;
    }

    try {
      res = await lootboxService.openBox(campaign.Lootbox.id);
    } catch (err) {
      setLoading(false);
      setShow(false);
      return;
    }
    setOpenDetail(res.data);
    setLoading(false);
  };

  const genCurrency = () => {
    if (campaign) {
      switch (campaign.Lootbox.currency_id) {
        case 1:
          return 'SPO';
        case 2:
          return 'USDT';
        case 3:
          return 'USDC';
        case 4:
          return 'BUSD';
      }
    }
  };
  const handleCancelResell = async () => {
    let res = null;
    try {
      setLoading(true);
      res = await checkoutService.cancelSecondsale(campaign.Lootbox.id);
    } catch (err) {
      setLoading(false);
      refresh();
      return;
    }
    if (res && res.data) {
      notification.success({ message: 'Cancel resell box success' });
    }
    setLoading(false);
    refresh();
  };
  return (
    <Fragment>
      {show && <Legend openDetail={openDetail} loading={loading} />}
      <div className='row detail-box'>
        <div className='col-md-5 col-xs-12 image-detail-box'>
          <div className='flex-center image-box image-contain p-24' style={{ height: '100%', width: '100%' }}>
            <img src={campaign?.LootboxCampaign?.box_image} style={{ maxHeight: '100%', maxWidth: '100%' }} />
          </div>
        </div>
        <div className='col-md-7 col-xs-12 infor-product  d-flex flex-column check6'>
          <div>
            <div className='name-product'>
              <span>
                {campaign && campaign.LootboxCampaign.title} #{campaign && campaign.Lootbox.id}
              </span>
            </div>

            <div className='description-title'>Description</div>

            <div className='content-product'>
              <div className='author-infor'>
                <div className='author'>
                  <img
                    src={lootboxProfile && lootboxProfile.avatar ? lootboxProfile.avatar : avatar}
                    alt=''
                    width='24'
                    className='avattar'
                  />
                  <span>Owner by</span>{' '}
                  <span style={{ fontWeight: 700, marginLeft: 3 }}>
                    {' '}
                    {lootboxProfile && shorterName(lootboxProfile.fullname)}
                  </span>
                </div>
                <CopyToClipboard className='CopyToClipboard' text={lootboxProfile?.wallet_address}>
                  <div>
                    <span>{lootboxProfile && shortenAddress(lootboxProfile.wallet_address, 8, 8)}</span>
                    <img src={shape} alt='Copy to clipboard' />
                  </div>
                </CopyToClipboard>
                
              </div>
              <div className='description'>{campaign && campaign.LootboxCampaign.content}</div>
            </div>
          </div>
          {/* <div className="content-product">
                        <span className="content">
                            {campaign && campaign.content}
                        </span>
                    </div> */}
          <div className='group-btn-box d-flex align-items-end' style={{ direction: 'rtl' }}>
            {campaign && !campaign.Lootbox.is_resell && (
              <Fragment className='row col-lg-9'>
                <PutOnSaleBoxModal
                  refresh={refresh}
                  campaign={campaign}
                  commissionFee={commissionFee}
                  idLootbox={campaign ? campaign.Lootbox.id : ''}
                />
                <a
                  onClick={() => {
                    handleOpen();
                  }}
                  className='a-button a-button-color a-bg-color-gray col-5 mr-2'
                >
                  OPEN BOX
                </a>
              </Fragment>
            )}
            {campaign && campaign.Lootbox.is_resell && (
              <Fragment>
                <a
                  onClick={() => {
                    handleCancelResell();
                  }}
                  style={{ marginLeft: 'auto' }}
                  className='col-5 a-button a-bg-color-yellow'
                >
                  {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                  CANCEL
                </a>
                <div className='col-7 price-product p-0'>
                  <div className='price-text' style={{ textAlign: 'left' }}>
                    Price
                  </div>
                  <div className='price-value' style={{ textAlign: 'left' }}>
                    {campaign && campaign.Lootbox.resell_price} {genCurrency()}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default BoxDetailOpen;
