import ReactDOM from 'react-dom';
import App from 'App';
import 'i18n';
import 'antd/dist/antd.min.css';
import 'App.scss';
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'antd/dist/antd.css';

ReactDOM.render(<App />, document.getElementById('root'));
