import React, { Fragment, useEffect, useState, useMemo } from 'react';
import DropItem from './DropItem';
import { Space, Select, Row, Col, List } from 'antd';
import { campaignService } from 'services/lootbox/campaign';
import './boxDetail.scss';
import { Card, Button } from 'reactstrap';
import PopupDetail from 'views/Campain/component/PopupDetail';

export default function DropList(props) {
  const {arrayTotal, lootbox, campaign, dropItem } = props;
  const [isPopup, setIsPopup] = useState(false);
  const [itemDetail, setItemDetail] = useState({});
  
  const [isShowImages,setShowImage] = useState(false);



  const newCampaign = useMemo(() => {
    return {
      campaign_wave: {
        ...campaign?.CampaignWave,
        lootbox_campaign: campaign?.LootboxCampaign
      }
    }
  }, [campaign])

 

  console.log('campaign', newCampaign, lootbox)
  return (
    <Fragment>
      <div className='col-12 p-0'>
        <div className='row list-drop-items col-12 p-0'>
          <div className='list-item col-12 p-0'>
            {/* {[ {}, {}, {}, {},{}, {}, {}, {}, {}, {}].map((item) => {
                                    return <DropItem/>;
                                    })} */}
            <List
              grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 4,
                xxl: 5,
              }}
              dataSource={lootbox}
              renderItem={(item) => (
                <List.Item>
                  <DropItem drop={item} setItemDetail={setItemDetail} setIsPopup={setIsPopup}/>
                </List.Item>
              )}
            />
          </div>
        </div>
        
        {isPopup && (
          <div onClick = {() => { console.log("click on") } } className='popup d-flex'style={{justifyContent:"center"}} >
            <PopupDetail
              item={itemDetail}
              campaign={newCampaign}
              isPopup={isPopup}
              setIsPopup={setIsPopup}
              isShowImages={isShowImages}
              setIsShowImages={setShowImage}
              setItemDetail={setItemDetail}
              dropItem={dropItem}
              arrayTotal={arrayTotal}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
}
