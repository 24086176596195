/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import iconRight from '../../assets/images/arrow-right.png';
import './style.scss';
import { shorterName } from 'utils/common';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CountDownItem from './CountDownItem';
var settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function CountDown(props) {
  const history = useHistory();
  const refSlideCampaign = useRef(null);
  const { data,refresh } = props;
  return (
    <div className='count-down-sl'>
      <Slider ref={refSlideCampaign} {...settings}>
        {data &&
          data.length !== 0 &&
          data.map((item, idx) => {
            const detailCampaign = item;
            const nowDate = new Date().getTime();
            const endDate = new Date(item.end_time).getTime();
            if (endDate > nowDate) {
              return (
                <div key={item.id}>
                  <CountDownItem data={item} refresh={refresh}></CountDownItem>
                </div>
              );
            }
          })}
      </Slider>
    </div>
  );
}
export default CountDown;
