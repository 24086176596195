import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField, debounce } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { marketService } from 'services/market';
import { color } from 'utils/constants';
import { privateRoute } from 'routes';
import { stringify } from 'query-string';

import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';

const CssTextField = withStyles({
  root: {
    margin: 0,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
  },
})(TextField);

const SearchBar = () => {
  const history = useHistory();
  const location = useLocation();

  const [dataList, setDataList] = React.useState([]);
  const [dataLoading, setDataLoading] = React.useState(false);

  const [value, setValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  const fetchData = (keywords) => {
    setDataLoading(true);
    marketService
      .searchItem({ keywords })
      .then(({ data }) => {
        setDataList(data.nfts);
      })
      .finally(() => {
        setDataLoading(false);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = React.useCallback(
    debounce((value) => {
      fetchData(value);
    }, 300),
    [],
  );

  React.useEffect(() => {
    if (location.pathname !== privateRoute.search.path) {
      setInputValue('');
    }
  }, [location.pathname]);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      style={{ flex: 3, minWidth: 120, marginRight: 20 }}
      options={dataList.map((item) => item.name)}
      renderInput={(params) => (
        <CssTextField
          {...params}
          margin='dense'
          placeholder='Search by collectible, creator...'
          InputProps={{
            ...params.InputProps,
            endAdornment: <SearchOutlinedIcon color='action' />,
            style: { backgroundColor: color.grey },
          }}
        />
      )}
      loading={dataLoading}
      value={value}
      inputValue={inputValue}
      onChange={(event, value) => {
        setInputValue(value);
        history.push({
          pathname: privateRoute.search.path,
          search: stringify({ keywords: value }),
        });
      }}
      onInputChange={(event, value) => {
        setValue('');
        setInputValue(value);
        debounceChange(value);
      }}
    />
  );
};

export default SearchBar;
