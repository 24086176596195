import { store } from 'reducers';
import { ActionType } from 'reducers/loobox/profile';
import { notification } from 'antd';
import { userServiceLb } from 'services/lootbox/user';
import Web3 from 'web3';

const requireMetaMask = () => {
  notification.error({
    message: 'Cannot connect to MetaMask',
    description: (
      <a
        href='https://metamask.io/download.html'
        className='MuiTypography-colorSecondary'
        target='_blank'
        rel='noreferrer'
      >
        Install MetaMask
      </a>
    ),
    duration: 10,
  });
};

const connect = async (callback) => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    return requireMetaMask();
  }

  const [user] = await window.ethereum.request({ method: 'eth_requestAccounts' });
  const { isLoggedIn } = store.getState().profileLootbox;
  if (isLoggedIn) {
    if (typeof callback === 'function') callback();
  } else {
    try {
      let res = null;
      try {
        res = await userServiceLb.nonce({ public_address: user });
      } catch {}
      if (res == null) {
        try {
          try {
            await userServiceLb.register({ public_address: user });
          } catch {}
          res = await userServiceLb.nonce({ public_address: user });
        } catch (e) {}
      }

      const message = `lootbox login one-time code: ${res.data}`;
      const signature = await window.web3.eth.personal.sign(message, user);
      const result = await userServiceLb.login({ public_address: user, signature });
      signInLb({ id: user, ...result });
      fetchProfile();
      if (typeof callback === 'function') callback();
    } catch (error) {
      if (error.code === 4001) {
        notification.error({ message: 'Please connect to MetaMask' });
        return 1;
      }
    }
  }
};

const signInLb = (profile) => {
  store.dispatch({
    type: ActionType.USER_LOGIN_LOOTBOX,
    data: profile,
  });
};

const fetchProfile = async () => {
  const { data } = await userServiceLb.getProfile();
  store.dispatch({
    type: ActionType.USER_FETCH_PROFILE_LOOTBOX,
    data: data,
  });
};

const signOut = () => {
  store.dispatch({
    type: ActionType.USER_LOGOUT_LOOTBOX,
  });
};

export const lbprofileAction = {
  connect,
  fetchProfile,
  signInLb,
  signOut,
  requireMetaMask,
};
