import React from 'react';
import { Button, Box, CardMedia, makeStyles, Container } from '@material-ui/core';
import { Col, Row } from 'antd';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import Slider from 'react-slick';
import ViewImage from './ViewImage';
import TextOverFlow from './TextOverFlow';

const slideOptions = {
  autoplay: true,
  vertical: true,
  centerMode: true,
  arrows: false,
  swipe: false,
  slidesToShow: 3,
};

const TopItems = () => {
  const classes = useStyles();
  const slide = React.useRef(null);

  const [dataList, setDataList] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const fetchData = React.useCallback(() => {
    marketService.searchItem({ limit: 8 }).then(({ data }) => {
      setDataList(data.nfts.map(normalizeItem));
    });
  }, []);

  const sliceTo = (index) => {
    if (index !== activeIndex) {
      if (activeIndex === 0 && index === dataList.length - 1) slide.current.slickPrev();
      else if (activeIndex === dataList.length - 1 && index === 0) slide.current.slickNext();
      else slide.current.slickGoTo(index);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box className={classes.background}>
      <Container className={classes.container}>
        <Row>
          <Col lg={6} md={8} style={{ zIndex: 1 }}>
            <TextOverFlow item={dataList[activeIndex]} />
          </Col>
          <Col lg={14} md={16} className='flex-row justify-content-center pl-32'>
            <Box className={' flex-column align-items-center'} style={{ width: 560, height: 600 }}>
              <ViewImage
                itemFront={dataList[activeIndex]}
                itemBack={dataList[(activeIndex - 1 + dataList.length) % dataList.length]}
              />
              <Box style={{ marginTop: 60, marginRight: 52, height: 24 }}>
                {Array.from({ length: dataList.length }).map((item, index) => {
                  const selected = index === activeIndex;
                  return (
                    <Button
                      key={index}
                      onClick={() => sliceTo(index)}
                      className={classes.buttonPick}
                      style={{
                        backgroundColor: '#fffc',
                        opacity: selected ? 1 : 0.7,
                        minWidth: selected ? 60 : 36,
                        height: selected ? 4 : 2,
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          </Col>

          <Col lg={4} className='TopItems-Slider flex-row justify-content-end' style={{ maxHeight: 600 }}>
            <Slider
              ref={slide}
              {...slideOptions}
              style={{ width: 200 }}
              beforeChange={(_, next) => setActiveIndex(next)}
            >
              {dataList?.map((item, index) => {
                const selected = index === activeIndex;
                return (
                  <Box key={item.id}>
                    <Box className='flex-center' style={{ height: 180, filter: `brightness(${selected ? 1 : 0.5})` }}>
                      <CardMedia
                        image={item.image}
                        onClick={() => sliceTo(index)}
                        style={{
                          height: 160,
                          width: '100%',
                          margin: 5,
                          position: 'relative',
                          backgroundColor: '#3008',
                          border: '1px solid silver',
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          hidden={!selected}
                          style={{ position: 'absolute', inset: -5, border: '5px solid silver' }}
                        />
                      </CardMedia>
                    </Box>
                  </Box>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    background: `radial-gradient(50% 50%, #ea6a1eb3, #fff0), url(/assets/images/top-background.png)`,
    padding: '60px 0px',
    marginTop: -24,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  container: {
    minHeight: 600,
  },
  buttonPick: {
    margin: 6,
    padding: 0,
  },
}));

export default TopItems;
