import React, { Fragment } from 'react';
import Player from 'react-player'
// import { findDOMNode } from 'react-dom'
// import screenfull from 'screenfull'
import video from '../../assets/video/NFT_Unboxing_FINAL.mp4'
import './open.scss';
import { CardImg, Button } from 'reactstrap';

export default function Item(props) {
  const { openDetail, loading } = props;
  const genClass = () => {
    if (openDetail) {
      if (openDetail.nft_type?.id === 1) {
        return 'rarity_btn rarity_legend';
      }
      if (openDetail.nft_type?.id === 2) {
        return 'rarity_btn rarity_epic';
      }
      if (openDetail.nft_type?.id === 3) {
        return 'rarity_btn rarity_super_rare';
      }
      if (openDetail.nft_type?.id === 4) {
        return 'rarity_btn rarity_rare';
      }
      if (openDetail.nft_type?.id === 5) {
        return 'rarity_btn rarity_common';
      }
      return '';
    }
  };


  return (
    <div className='item'>
      <Fragment>
        {loading ? (
          <div className='loading-video'>
            {/* <CircularProgress className={classes.buttonProgress} size={24} /> */}
           <Player url={video}
              width='100%'
              height='100%'
              className='react-player'
              playing={true}
              loop={true}
              controls={false}
           />
          </div>
        ) : (
          <Fragment>
            <div>
              {openDetail?.user_nft?.video
                ? <video width='228' controls>
                  <source src={openDetail?.user_nft?.video} />
                </video> : <CardImg
                top
                width='100%'
                src={openDetail && openDetail.user_nft?.image !== '<nil>' ? openDetail.user_nft?.image : ''}
                alt='Card image cap'
                className='image-card'
              />
              }
              
            </div>
          </Fragment>
        )}

        <div style={{ width: '100%', textAlign: 'center' }}>
          {openDetail && <Button className={genClass()}>{openDetail && openDetail.nft_type?.label}</Button>}
        </div>
      </Fragment>
    </div>
  );
}
