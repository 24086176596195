import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Box, Paper, Typography, Tabs, Tab, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BackgroundColor } from 'components';
import { Col, Row, Image, Spin } from 'antd';
import { marketService } from 'services/market';
import { normalizeItem } from 'utils/converter';
import { BoxCreator, BoxPrice, BoxDescription } from './components';
import { TabHistorySales, TabHistoryBids, RelatedArtist, RelatedItems } from './components';

const ExploreView = () => {
  const classes = useStyles();

  const { id } = useParams();
  const { id: user, ...profile } = useSelector(({ profile }) => profile);

  const [isLoading, setIsLoading] = React.useState(false);
  const [apiItem, setItem] = React.useState({});
  const [historyBids, setHistoryBids] = React.useState([]);
  const [historySales, setHistorySales] = React.useState([]);
  const [activeTab, setActiveTab] = React.useState('history');

  const nftItem = React.useMemo(() => {
    const item = normalizeItem(apiItem, profile);
    const highestBid = historyBids[0] ?? {
      offerPrice: item.saleInfo.price,
      paymentToken: item.saleInfo.paymentToken,
    };
    return {
      ...item,
      isAuctionBids: historyBids.length > 0,
      highestBid,
      isUserHighestBid: profile.address ? profile.address === highestBid.creatorAddr : false,
    };
  }, [apiItem, profile, historyBids]);

  const tabs = (
    nftItem.isAuction ? [{ id: 1, code: 'bids', label: 'Bids', component: <TabHistoryBids items={historyBids} /> }] : []
  ).concat([{ id: 2, code: 'history', label: 'History', component: <TabHistorySales items={historySales} /> }]);

  const fetchData = React.useCallback(() => {
    setIsLoading(true);
    marketService
      .getItem({ id })
      .then(({ data }) => {
        setItem(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const fetchHistoryBids = React.useCallback(() => {
    if (nftItem.saleInfo.id)
      marketService.getHistoryBids({ saleId: nftItem.saleInfo.id }).then(({ data }) => {
        setHistoryBids(data.bids.filter((item) => item.status === 0));
      });
  }, [nftItem.saleInfo.id]);

  const fetchHistorySales = React.useCallback(() => {
    if (id) {
      setHistorySales([]);
    }
  }, [id]);

  React.useEffect(() => {
    setActiveTab(apiItem.isAuction ? 'bids' : 'history');
  }, [apiItem.isAuction]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    fetchHistoryBids();
  }, [fetchHistoryBids]);

  React.useEffect(() => {
    fetchHistorySales();
  }, [fetchHistorySales]);

  return (
    <BackgroundColor>
      <Spin spinning={isLoading}>
        <Container>
          <Paper className={classes.paper}>
            <Row gutter={24 * 4}>
              <Col style={{ flex: 3, minHeight: 360, minWidth: 360 }} className='flex-center'>
                {nftItem.isVideo ? (
                  <video src={nftItem.image} style={{ maxHeight: 480, minWidth: 120 }} controls />
                ) : (
                  <Image src={nftItem.image} style={{ maxHeight: 480, minWidth: 120 }} />
                )}
              </Col>
              <Col style={{ flex: 2 }} className='flex-column'>
                <Typography variant='h4'>{nftItem.name}</Typography>
                <Divider className='my-12' />
                <BoxCreator item={nftItem} />
                <Box flex={1} />
                <BoxPrice
                  item={nftItem}
                  onRefresh={fetchData}
                  onRefreshBids={fetchHistoryBids}
                  onRefreshSales={fetchHistorySales}
                />
              </Col>
            </Row>
          </Paper>
          <Row gutter={24} className='mt-24'>
            <Col style={{ flex: 3 }}>
              <Paper className={classes.paper}>
                <BoxDescription item={nftItem} />
              </Paper>
            </Col>
            <Col style={{ flex: 2 }}>
              <Paper className={classes.paper}>
                <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
                  {tabs.map((tab) => (
                    <Tab key={tab.id} label={tab.label} value={tab.code} style={{ minWidth: 80 }} />
                  ))}
                </Tabs>
                {tabs.map((tab) => (
                  <Box key={tab.id} hidden={tab.code !== activeTab} py={2}>
                    {tab.component}
                  </Box>
                ))}
              </Paper>
            </Col>
          </Row>
        </Container>
      </Spin>

      {apiItem.id && <RelatedArtist ignored={id} artist={apiItem.owner.address} />}
      {apiItem.id && user !== apiItem.owner.address && (
        <RelatedItems
          nftID={id}
          ownerAddr={apiItem.owner.address}
          creatorAddr={nftItem.creatorAddr}
          subCategoryID={nftItem.subCategoryID}
        />
      )}
    </BackgroundColor>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    height: '100%',
  },
}));

export default ExploreView;
