import React, { Component, Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './itemDetail.scss';
import coin from 'assets/images/coin.png';
import avatar from 'assets/images/person.png';
import { Card } from 'reactstrap';
import CheckoutModal from 'components/checkout/Checkout';
import { Button, Space, Select, List, Tabs } from 'antd';
import ArtistItem from 'components/box/ArtistItem';
import HistoryLineItem from 'components/item/HistoryLineItem';
import HistoryLineItemSale from 'components/item/HistoryLineItemSale';
import HistoryLineItemOwner from 'components/item/HistoryLineItemOwner';
import ItemInfor from 'components/item/ItemInforSale';
import { lootboxService } from 'services/lootbox/lootbox';

const { TabPane } = Tabs;

const DetailItemSale = () => {
  // const [lootbox, setLootbox] = useState(null);
  const [lootbox, setLootbox] = useState(null);
  const [config, setConfig] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [owner,setOwner] = useState(null)
  const [commissionFee, setCommission] = useState(0);
  useEffect(async () => {
    try {
      const res = await lootboxService.userNftOne(id);
      setLootbox(res.data);
      setCommission(res.commission_fee);
    } catch (error) {
      history.push('/app/box-profile');
    }
  }, []);
  useEffect(async()=>{
    if(lootbox){
     const res = await lootboxService.ownerItem(lootbox.token_id)
     if(res){
       setOwner(res.data)
     }
    }
   },[lootbox])

  return (
    <Fragment>
      <div className='item-detail-page'>
        <div className='container'>
          <div className='row detail-item-box' style={{ paddingBottom: '15px' }}>
            <ItemInfor lootbox={lootbox} commissionFee={commissionFee} />
          </div>

          <div className='row description-and-history mb-4'>
            <div className='col-md-5 pl-0 col-xs-12'>
              <div className='description-card'>
                <div className='description-text f-700'>About artist</div>
                <div className='description-text f-700' style={{ fontSize: '14px' }}>
                  Create by {lootbox && lootbox.artist_name}
                </div>

                <div className='description-content'>
                  <div className='about-artirt'>
                    <span>{lootbox && lootbox.artist_description}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-7 col-xs-12 p-0'>
              <div className='history-card'>
                <Tabs defaultActiveKey='1' animated={false}>
                  <TabPane
                    tab={
                      <Button size='medium' type='Default' shape='round'>
                        Owner
                      </Button>
                    }
                    key='1'
                  >
                    <HistoryLineItemOwner items={owner} />
                  </TabPane>
                  <TabPane
                    tab={
                      <Button size='medium' type='Default' shape='round'>
                        History
                      </Button>
                    }
                    key='2'
                  >
                    <HistoryLineItemSale items={lootbox && lootbox.history} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DetailItemSale;
