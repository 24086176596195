import React from 'react';
import { CircularProgress, CircularProgressProps } from '@material-ui/core';

interface LoadingProps extends CircularProgressProps {
  visible?: boolean;
  size?: number;
  icon?: any;
}

const Loading = ({ visible, size = 20, icon, ...props }: LoadingProps) =>
  visible ? <CircularProgress {...props} size={size} color='inherit' /> : icon ?? null;

export default Loading;
