import React, { Component, Fragment, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './itemDetail.scss';
import avatar from 'assets/images/person.png';
import { Card } from 'reactstrap';
import { List, notification } from 'antd';
import ArtistItem from 'components/box/ArtistItem';
import HistoryLineItem from 'components/item/HistoryLineItem';
import ItemInfor from 'components/item/ItemInfor';
import { lootboxService } from 'services/lootbox/lootbox';
import { campaignService } from 'services/lootbox/campaign';
import { checkoutService } from 'services/lootbox/checkout';
import { authorService } from 'services/lootbox/authorService';
import { images } from 'assets/images';
import HistoryLineItemSale from 'components/item/HistoryLineItemSale';
import HistoryLineItemOwner from 'components/item/HistoryLineItemOwner';
import { Button, Space, Select, Tabs } from 'antd';
const { TabPane } = Tabs;

const DetailItem = () => {
  // const [lootbox, setLootbox] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [item, setItem] = useState(null);
  const history = useHistory();
  const [owner,setOwner] = useState(null)
  const { id } = useParams();

  const getImage = (name) => {
    switch (name) {
      case 'Legandary':
        return images.legend;
        break;
      case 'Epic':
        return images.epic;
        break;
      case 'Supper rate':
        return images.super_rarity;
        break;
      case 'Rate':
        return images.rariryc;
        break;
      case 'Common':
        return images.common;
        break;
      default:
        break;
    }
  };

  const [campaigns, setCampaigns] = useState([]);
  const [author, setAuthor] = useState([]);
  useEffect(async () => {
    //    const {data : result} = lootboxService.lootboxDetail(id);
    //    setLootbox(result);
    //   const res = await campaignService.campaign(id);
    try {
      const item = await lootboxService.detailresellItem(id);
      setItem(item.data);
    } catch (error) {
      notification.error({ message: 'Item not found' });
      history.push('/app/market');
    }
    //   setCampaign(res.data)
  }, []);
  useEffect(async()=>{
   if(item){
    const res = await lootboxService.ownerItem(item.Onsell.NftProfile.token_id)
    if(res){
      setOwner(res.data)
    }
   }
  },[item])
  return (
    <Fragment>
      <div className='item-detail-page'>
        <div className='container'>
          <div className='row detail-item-box' style={{ paddingBottom: '15px' }}>
            <ItemInfor item={item} />
          </div>

          <div className='row description-and-history mb-4'>
            <div className='col-md-5 pl-0 col-xs-12'>
              <div className='description-card'>
                <div className='description-text f-700'>About artist</div>
                <div className='description-text f-700' style={{ fontSize: '14px' }}>
                  Create by {item && item.Campaign.artist_name}
                </div>

                <div className='description-content'>
                  <div className='about-artirt'>
                    <span>{item && item.Campaign.artist_description}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-7 col-xs-12 p-0'>
              <div className='history-card'>
                <Tabs defaultActiveKey='1' animated={false}>
                  <TabPane
                    tab={
                      <Button size='medium' type='Default' shape='round'>
                        Owner
                      </Button>
                    }
                    key='1'
                  >
                    <HistoryLineItemOwner items={owner} />
                  </TabPane>
                  <TabPane
                    tab={
                      <Button size='medium' type='Default' shape='round'>
                        History
                      </Button>
                    }
                    key='2'
                  >
                    <HistoryLineItemSale items={item && item.History} />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DetailItem;
