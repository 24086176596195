import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Box, Card, CardMedia, Divider, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CountdownTimer } from 'components';
import { normalizeItem } from 'utils/converter';
import { privateRoute } from 'routes';

const CardItem = ({ item: apiItem }) => {
  const { ...profile } = useSelector(({ profile }) => profile);

  const item = React.useMemo(() => normalizeItem(apiItem, profile), [apiItem, profile]);

  return (
    <>
      {item.isErc1155 && (
        <>
          <Paper
            variant='outlined'
            className='Card-Container'
            style={{ position: 'absolute', height: 40, bottom: -8, left: 28, right: 28 }}
          />
          <Paper
            variant='outlined'
            className='Card-Container'
            style={{ position: 'absolute', height: 40, bottom: -4, left: 20, right: 20 }}
          />
        </>
      )}

      <Card variant='outlined' className='Card-Container'>
        <CardMedia
          image={item.isVideo ? item.preview : item.image}
          component={Link}
          to={privateRoute.exploreView.url(item.id)}
          style={{ height: 240 }}
        >
          <Skeleton variant='rect' width='100%' height='100%' />
        </CardMedia>
        <Divider />

        <Box style={{ padding: '8px 12px 4px' }}>
          <Typography variant='subtitle1' className='text-ellipsis' title={item.name}>
            <Link to={privateRoute.exploreView.url(item.id)}>{item.name}</Link>
          </Typography>
        </Box>
        <Box className='align-items-center' style={{ padding: '4px 12px 8px' }}>
          <Link to={privateRoute.artist.url(item.owner.address)} className='mr-8'>
            <Avatar src={item.owner.avatar} style={{ width: 28, height: 28 }} />
          </Link>
          <Typography variant='body2' color='textSecondary'>
            {item.owner.name}
          </Typography>
        </Box>
        <Divider className='mx-12 my-4' />
        {item.isAuction || item.isFixedPrice ? (
          <Box className='justify-content-between align-items-start' style={{ padding: '8px 12px 12px' }}>
            <Typography variant='subtitle1'>
              {item.saleInfo.highestPrice || item.saleInfo.price} {item.saleInfo.paymentToken.symbol}
            </Typography>
            {item.isAuction && <CountdownTimer endTime={item.saleInfo.endTime} />}
          </Box>
        ) : (
          <Box className='flex-center' style={{ padding: '8px 12px 12px' }}>
            {item.isUserOwner ? (
              <Typography>
                <Link to={privateRoute.createView.url(item.id)}>Put on Market</Link>
              </Typography>
            ) : (
              <Typography color='textSecondary'>Not for Sale</Typography>
            )}
          </Box>
        )}
      </Card>
    </>
  );
};

const CardSkeleton = () => {
  return (
    <Card variant='outlined' style={{ borderRadius: 8 }}>
      <CardMedia style={{ height: 240 }}>
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Divider />
      <Typography style={{ padding: '8px 12px 4px' }}>
        <Skeleton />
      </Typography>
      <Typography style={{ padding: '4px 12px 8px' }}>
        <Skeleton />
      </Typography>
      <Box>
        <Skeleton variant='rect' width='100%' height={57} />
      </Box>
    </Card>
  );
};

CardItem.Skeleton = CardSkeleton;

export default CardItem;
