/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import iconRight from '../../assets/images/arrow-right.png';
import './style.scss';
import { shorterName } from 'utils/common';

function CountDownItem(props) {
  const [durationDiff, setDurationDiff] = useState(null);
  const history = useHistory();
  const [status, setStatus] = useState('BUY NOW');
  useEffect(() => {
    if (data) {
      countDownTime();
    }
  }, [data]);
  const { data,refresh } = props;
  function countDownTime() {
    const eventTime = moment(data.end_time).unix();
    const currentTime = moment().unix();
    const start_time = moment(data.start_time).unix();
    let diffTime = start_time - currentTime;
    setStatus('COMING SOON');
    if (diffTime <= 0) {
      setStatus('BUY NOW');
      diffTime = eventTime - currentTime;
    }
    if (diffTime >= 0) {
      let ress = moment.duration(diffTime * 1000, 'milliseconds');
     const i = setInterval(function () {
        ress = moment.duration(ress - 1000, 'milliseconds');
        if (ress >= 0) {
          setDurationDiff(ress);
        } else {
          setDurationDiff(0);
          handleClearInterval()
          refresh()
        }
      }, 1000);
      handleSaveInterval(i)
    }
  }
  const handleClearInterval =()=>{
    const listInterval = localStorage.getItem("liveAuction");
  if(listInterval && listInterval != undefined && listInterval != ""){
    try {
      const objectM = JSON.parse(listInterval);
      if(objectM && objectM.length > 0){
        objectM.map(m =>{
          clearInterval(m);
        })
      }
    } catch (error) {
      localStorage.removeItem("liveAuction")
    }

  }
  localStorage.removeItem("liveAuction")
}

const handleSaveInterval =(idIn)=>{
  const listInterval = localStorage.getItem("liveAuction");
  const newInter = [];
  if(listInterval && listInterval != undefined && listInterval != ""){
    try {
      const objectM = JSON.parse(listInterval);
      if(objectM && objectM.length > 0){
        objectM.push(idIn)
        localStorage.setItem("liveAuction",JSON.stringify(objectM))
      }
      
    } catch (error) {
      localStorage.removeItem("liveAuction")
    }

  }else{
    newInter.push(idIn);
    localStorage.setItem("liveAuction",JSON.stringify(newInter))
  }
}
  return (
    <div className='count-down countdown-image' style={{ width: '100%', height: '100%',backgroundRepeat:"no-repeat",backgroundSize:"100%", backgroundImage:`url(${data.lootbox_campaign.banner_image})` }}>
      <div className='sub-artist'>
        Artist: {data && data.lootbox_campaign && shorterName(data.lootbox_campaign.artist_name, 100)}
      </div>
      <div className='title'>
        {data && data.lootbox_campaign && data.lootbox_campaign.title} {data && data.wave_name && '- ' + data.wave_name}
      </div>
      <div className='sub-total'>
        TOTAL: {data && data.max_box_quantity} {data.max_box_quantity > 1 ? 'BOXES' : 'BOX'}
      </div>
      <div className='campaign-start' style={{ display: durationDiff == null ? 'none' : 'block' }}>
        <div className='sub-title'>{status !== 'BUY NOW' ? 'CAMPAIGN STARTS IN' : 'CAMPAIGN ENDS IN'}</div>
        <div className='time-countdown'>
          <div className='item-time'>
            <div className='box-time'>
              {moment.duration(durationDiff).format('DD:hh:mm:ss', { trim: false }).split(':')[0]}
            </div>
            Days
          </div>
          <div className='dot'>:</div>
          <div className='item-time'>
            <div className='box-time'>
              {moment.duration(durationDiff).format('DD:hh:mm:ss', { trim: false }).split(':')[1]}
            </div>
            Hours
          </div>
          <div className='dot'>:</div>
          <div className='item-time'>
            <div className='box-time'>
              {moment.duration(durationDiff).format('DD:hh:mm:ss', { trim: false }).split(':')[2]}
            </div>
            Minutes
          </div>
          <div className='dot'>:</div>
          <div className='item-time'>
            <div className='box-time'>
              {moment.duration(durationDiff).format('DD:hh:mm:ss', { trim: false }).split(':')[3]}
            </div>
            Seconds
          </div>
        </div>
      </div>
      <div className='sold-out' style={{ display: durationDiff != null ? 'none' : 'block' }}>
        <div className='sub-title-soldout'>SOLD OUT</div>
      </div>
      <div className='btn-see-more' onClick={() =>  {
        console.log('test====>', `/campaign/${data.lootbox_campaign.slug}/${data.wave_slug}`)
        history.push(`/campaign/${data.lootbox_campaign.slug}/${data.wave_slug}`)
      }}>
        See more <img className='d-none' src={iconRight} />
      </div>
    </div>
  );
}
export default CountDownItem;
