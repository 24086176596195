import slide1 from 'assets/images/bgprofile.png';
import vector1 from 'assets/images/Vector1.png';
import { FILES_ACCEPT_AVATAR } from 'constants/common';
import React, { Fragment, useRef, useState } from 'react';
import './boxProfile.scss';
import ImageProfileSelect from './ImageProfileSelect';

const HeaderBoxProfile = (props) => {
  const { profile } = props;
  const refFile = useRef(null);
  const [isHover, setHover] = useState(false);

  const handleFile = (e) => {
    const files = e.target.files;

    const file = files[0];
  };

  const onMouseMove = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  const handleUpload = () => {
    refFile.current.click();
  };
  return (
    <Fragment>
      <input ref={refFile} type='file' hidden onChange={handleFile} accept={FILES_ACCEPT_AVATAR.join(',')} />
      <div className='header-box-profile'>
        <div className='header-box d-flex flex-column' style={{ backgroundImage: `url(${slide1})` }}></div>
      </div>
    </Fragment>
  );
};
export default React.memo(HeaderBoxProfile);
