import { Button, List, ListItem, Paper } from '@material-ui/core';
import { Dropdown } from 'antd';

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

const ButtonSelect = ({ options, children, ...props }) => {
  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <div>
          <List disablePadding component={Paper}>
            {options}
          </List>
        </div>
      }
    >
      <Button variant='outlined' endIcon={<ArrowDropDownOutlinedIcon />} {...props}>
        {children}
      </Button>
    </Dropdown>
  );
};

const Option = (props) => <ListItem button {...props} />;
ButtonSelect.Option = Option;

export default ButtonSelect;
