import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { PrivateLayout } from 'layouts';
import { AppTheme, AppError } from 'containers';
import { store } from 'reducers';
import { ConfigProvider as AntTheme } from 'antd';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { default as ScrollMemory } from 'react-router-scroll-memory';
import { default as MomentUtils } from '@date-io/moment';
import * as config from 'config';

config.run();

const App = () => {
  const [isReady, setIsReady] = React.useState(false);
  React.useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    isReady && (
      <ReduxProvider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AntTheme componentSize='large'>
            <AppTheme>
              <Router>
                <ScrollMemory />
                <AppError>
                  <Switch>
                    <Route path='/' component={PrivateLayout} />
                  </Switch>
                </AppError>
              </Router>
            </AppTheme>
          </AntTheme>
        </MuiPickersUtilsProvider>
      </ReduxProvider>
    )
  );
};

export default App;
