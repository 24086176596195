/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { Spin, Button } from 'antd';
import { SearchOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { API_URI_LOOTBOX } from 'env';
// import thumb_banner from '../../assets/images/thum5_slider.png';
import slide_campain1 from '../../assets/images/slideCampaign-1.png';
import slide_campain2 from '../../assets/images/slideCampaign-2.png';
import slide_campain3 from '../../assets/images/slideCampaign-3.png';
import slide_campain4 from '../../assets/images/slideCampaign-4.png';
import { fake_campaigns } from '../../assets/images';
import { campaignService } from 'services/lootbox/campaign';
import './campaign.css';
import { useHistory } from 'react-router';

var settings = {
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: false,
  infinite: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const styleSoldOut = {
  opacity: 0.5,
  objectFit: 'cover',
};
const styleI = {
  objectFit: 'cover',
};

const backgroundSoldout = {
  backgroundColor: '#434446',
  position: 'relative',
  textAlign: 'center',
};

const fake_data = fake_campaigns;

const SlideCampaign = (props) => {
  const { setItemCampain } = props;
  const refSlideCampaign = useRef(null);
  const [imageDefault, setImageDefault] = useState('');
  const [data, setData] = useState(null);
  //const urlImage = API_URI_LOOTBOX
  const urlImage = API_URI_LOOTBOX;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [setting, setSetting] = useState(settings);

  const onButtonClick = () => {
    refSlideCampaign.current.slickNext();
  };
  useEffect(() => {
    if (data) {
      const tem = { ...setting, slidesToShow: data.length > 1 ? 2 : 1 };
      setSetting(tem);
    }
  }, [data]);

  const detailCampaign = (item) => {
    if (props.setItemCampain) {
      props.setItemCampain(item);
    }
    history.push(`/campaign/${item.lootbox_campaign.slug}/${item.wave_slug}`)
  };
  useEffect(async () => {
    const res = await campaignService.campaigns();
    if (res && res.data && res.data.length > 0) {
      const filter = res.data.filter((m) => {
        const nowDate = new Date().getTime();
        const endDate = new Date(m.end_time).getTime();
        const startTime = new Date(m.start_time).getTime();
        if (endDate < nowDate) {
          return true;
        }
        return false;
      });
      if (filter && filter.length > 0) {
        setData(filter);
      }
    }
  }, []);

  return (
    <div className='row'>
      <div className='slide-campaign-wrapper mt-3 mr-4'>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin loading color='warning' />
          </div>
        ) : (
          <div>
            {data && <h3>{props.slide_name}</h3>}
            <div className={`slider ${data?.length > 2 && 'slider-large'}`}>
              <Slider ref={refSlideCampaign} {...setting}>
                {data &&
                  data.length !== 0 &&
                  data.map((item, idx) => {
                    const nowDate = new Date().getTime();
                    const endDate = new Date(item.end_time).getTime();
                    return (
                      <div key={item.id} style={nowDate > endDate ? backgroundSoldout : null} className='campaign-item'>
                        <div className='soldout-bg'>
                          <div
                            className='campaign-image-cover'
                            style={{ backgroundImage: `url(${item.lootbox_campaign.banner_image})` }}
                          ></div>
                          {/*<img style={nowDate > endDate ? styleSoldOut : styleI} src={ item.lootbox_campaign.banner_image } />*/}
                          {nowDate > endDate && (
                            <div className='item-info'>
                              <div
                                className='flex-column align-items-center cursor-pointer'
                                onClick={() => {
                                  detailCampaign(item);
                                }}
                              >
                                <div className='item-name'>{item.lootbox_campaign.title}</div>
                                <div className='text-soldout'>SOLD OUT</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
            <div className='mb-40' />
            { data?.length > 2
              && <div className='next-button'>
              <Button
                onClick={onButtonClick}
                type='link'
                icon={<ArrowRightOutlined className='next-icon' />}
                size='large'
              />
            </div>
            }
            
          </div>
        )}
      </div>
    </div>
  );
};
export default SlideCampaign;
