import Web3 from 'web3';

import minter from './abis/SporesNFTMinter.json';
import market from './abis/SporesNFTMarket.json';
import { abi as collection, bytecode } from './abis/Collection.json';

import erc20 from './abis/StandardERC20.json';
import erc721 from './abis/StandardERC721.json';
import erc1155 from './abis/StandardERC1155.json';

const web3 = () => new Web3(window.ethereum);

export const minterContract = (address) => new (web3().eth.Contract)(minter, address);
export const marketContract = (address) => new (web3().eth.Contract)(market, address);
export const collectionContract = (address) => new (web3().eth.Contract)(collection, address);
export const collectionBytecode = bytecode;

export const erc20Contract = (address) => new (web3().eth.Contract)(erc20, address);
export const erc721Contract = (address) => new (web3().eth.Contract)(erc721, address);
export const erc1155Contract = (address) => new (web3().eth.Contract)(erc1155, address);

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
