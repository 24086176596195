import { Explore, ExploreAuction } from 'views/Explore';
import { ExploreSearch } from 'views/ExploreSearch';
import { ExploreView } from 'views/ExploreView';
import { Create, CreateType, CreateSale } from 'views/Create';
import { Profile } from 'views/Profile';
import { ProfileUpdate } from 'views/lootbox/ProfileUpdate';
import { CollectionView } from 'views/CollectionView';
import { Market } from 'views/lootbox/Market';
import DetailBox from 'views/lootbox/box-detail';
import DetailBoxOpen from 'views/lootbox/box-detail-open';
import BoxProfile from 'views/lootbox/box-profile';
import DetailItem from 'views/lootbox/item-detail';
import DetailItemSale from 'views/lootbox/item-detail-sale/index';
import Detail from 'views/lootbox/item-detail-sale/Detail';
import { Campain, DetailCampain } from 'views/Campain';

const privateRoute = {
  home: {
    path: '/',
    component: Market,
  },
  explore: {
    path: '/explore',
    component: Market,
  },
  market: {
    path: '/app/market',
    component: Market,
    requiredLogin: true,
  },
  detailCampain: {
    path: '/campain/:id',
    component: DetailCampain,
  },
  
  campain: {
    path: '/campain',
    component: Market,
  },
  detailCampain1: {
    path: '/campaign/:waveName/:slug',
    url: (slug,waveName) => `/app/${waveName}/${slug}`,
    component: DetailCampain,
  },
  boxDetail: {
    path: '/app/box-detail/:id',
    url: (id) => `/app/box-detail/${id}`,
    component: DetailBox,
  },
  detailBoxOpen: {
    path: '/app/detail-box-open/:id',
    url: (id) => `/app/detail-box-open/${id}`,
    component: DetailBoxOpen,
    requiredLogin: true,
  },

  itemDetail: {
    path: '/app/detail-item/:id',
    url: (id) => `/app/detail-item/${id}`,
    component: DetailItem,
  },
  detailSale: {
    path: '/app/item-sale/:id',
    url: (id) => `/app/item-detail-sale/${id}`,
    requiredLogin: true,
    component: Detail,
  },
  detailItemSale: {
    path: '/app/item-detail-sale/:id',
    url: (id) => `/app/item-detail-sale/${id}`,
    component: DetailItemSale,
  },

  boxProfile: {
    path: '/app/box-profile',
    component: BoxProfile,
    requiredLogin: true,
  },
  profileUpdate: {
    path: '/app/profile-update',
    component: ProfileUpdate,
    requiredLogin: true,
  },
};

export default privateRoute;
