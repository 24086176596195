export const ActionType = {
  USER_LOGIN_LOOTBOX: 'USER_LOGIN_LOOTBOX',
  USER_FETCH_PROFILE_LOOTBOX: 'USER_FETCH_PROFILE_LOOTBOX',
  USER_LOGOUT_LOOTBOX: 'USER_LOGOUT_LOOTBOX',
};
const initState = {
  ...(localStorage.getItem('profile_lootbox') ? JSON.parse(localStorage.getItem('profile_lootbox')) : ''),
  isLoggedIn: localStorage.getItem('profile_lootbox') ? true : false,
};

const profileLootbox = (state = { ...initState }, { type, data }) => {
  switch (type) {
    case ActionType.USER_LOGIN_LOOTBOX:
      localStorage.setItem('profile_lootbox', JSON.stringify(data));
      return { ...data, isLoggedIn: true };
    case ActionType.USER_FETCH_PROFILE_LOOTBOX:
      const profile = { ...state, lootboxProfile: data };
      return profile;
    case ActionType.USER_LOGOUT_LOOTBOX:
      localStorage.removeItem('profile_lootbox');
      return {};
    default:
      return state;
  }
};

export default profileLootbox;
