import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import './open.scss';
import Artist from 'components/box/Artist';
import DropList from 'components/box/DropList';
import AboutArtist from 'components/box/AboutArtist';
import BoxInfor from 'components/box/BoxDetailOpen';
// import { campaignService } from "services/lootbox/campaign";
import { lootboxService } from 'services/lootbox/lootbox';
import c from 'assets/images/c.png';
import s from 'assets/images/s.png';
import r from 'assets/images/r.png';
import e from 'assets/images/e.png';
import rarity from 'assets/images/ic-rarity.png';
import { Button, Space, Card, notification } from 'antd';
import SeriaContent from 'components/box/SerialContent';
import { useSelector } from 'react-redux';

const DetailBoxOpen = () => {
  const [campaign, setCampaign] = useState(null);
  const { id } = useParams();
  const [serialContent, setSerialContent] = useState([]);
  const [dropItem, setDropItem] = useState([]);
  const [arrayTotal, setArrayTotal] = useState({});
  const [lootbox, setLootbox] = useState(null);
  const { lootboxProfile } = useSelector(({ profileLootbox }) => profileLootbox);
  const [view, setView] = useState('seria');
  const history = useHistory();
  const [commissionFee, setCommission] = useState(0);

  useEffect(async () => {
    if (!campaign || !campaign.LootboxCampaign.nfts) {
      return;
    }
    let summedAges = campaign.LootboxCampaign.nfts.reduce((a, c) => {
      let filtered = a.filter((el) => el.nft_type_id === c.nft_type_id);
      if (filtered.length > 0) {
        a[a.indexOf(filtered[0])].total_supply += +c.total_supply;
        a[a.indexOf(filtered[0])].amount += +1;
      } else {
        const tem = { ...c, amount: 1 };
        a.push(tem);
      }
      return a;
    }, []);
    // eslint-disable-next-line no-extend-native
    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop];
      }
      return total;
    };
    let arraySum = {
      totalAmount: summedAges.sum('amount'),
      totalSupply: summedAges.sum('total_supply'),
    };
    setArrayTotal(arraySum);
    setDropItem([...summedAges]);
  }, [campaign]);
  const refresh = async () => {
    const result = await lootboxService.lootboxDetail(id);
    setCampaign(result.data);
    setCommission(result.commission_fee);
  };
  useEffect(async () => {
    const { data: result } = lootboxService.lootboxDetail(id);
    setLootbox(result);
    try {
      const result = await lootboxService.lootboxDetail(id);
      setCampaign(result.data);
      setCommission(result.commission_fee);
    } catch (error) {
      history.push('/app/box-profile');
      notification.error({ message: 'lootbox not found' });
    }
  }, []);
  return (
    <Fragment>
      <div className='product-detail-page'>
        <div className='container'>
          <BoxInfor campaign={campaign} commissionFee={commissionFee} refresh={refresh} />
          <div className='row series-content'>
            <div className='col-12'>
              <div className='row title-series-content p-0'>
                <Space style={{ marginBottom: 16 }}>
                  <Button
                    className={`btn-handle ${view == 'seria' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('seria')}
                  >
                    Series Content
                  </Button>
                  <Button
                    className={`btn-handle ${view == 'dropItem' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('dropItem')}
                  >
                    Drop Items
                  </Button>
                  <Button
                    className={`btn-handle ${view == 'artirt' ? 'btn-acctive' : ''}`}
                    onClick={() => setView('artirt')}
                  >
                    About Artist
                  </Button>
                </Space>
              </div>
              {view && view === 'seria' && <SeriaContent dropItem={dropItem} arrayTotal={arrayTotal} />}
              {view && view === 'dropItem' && <DropList lootbox={campaign ? campaign.LootboxCampaign.nfts : []} arrayTotal={arrayTotal} campaign={campaign} dropItem={dropItem}/>}
              {view && view === 'artirt' && <AboutArtist lootbox={campaign ? campaign.LootboxCampaign : null} campaign={campaign} />}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DetailBoxOpen;
