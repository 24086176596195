import { Link } from 'react-router-dom';
import { Container, Typography, Link as NavLink, Box, IconButton, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageBar } from 'components';
import { privateRoute } from 'routes';
import { images } from 'assets/images';

const socialIcons = [
  { name: 'Medium', image: '/assets/socials/medium.svg', url: 'https://spores.medium.com' },
  { name: 'Instagram', image: '/assets/socials/instagram.svg', url: 'https://www.instagram.com/SporesNetwork' },
  { name: 'Pinterest', image: '/assets/socials/pinterest.svg', url: 'https://pinterest.com/SporesNetwork' },
  { name: 'Facebook', image: '/assets/socials/facebook.svg', url: 'https://www.facebook.com/SporesNetwork' },
  { name: 'Telegram', image: '/assets/socials/telegram.svg', url: 'https://t.me/sporeseng' },
  { name: 'Twitter', image: '/assets/socials/twitter.svg', url: 'https://twitter.com/Spores_Network' },
  {
    name: 'Youtube',
    image: '/assets/socials/youtube.svg',
    url: 'https://www.youtube.com/channel/UC9pgjF_aWprnVodvxO7OPkA',
  },
  { name: 'LinkedIn', image: '/assets/socials/linkedin.svg', url: 'https://www.linkedin.com/company/SporesNetwork' },
];

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Box className='containerBottom'>
        <Container className='flex-row flex-wrap'>
          <Box className='align-items-top col-sm-3'>
            <NavLink color='inherit' component={Link} to={privateRoute.home.path} className='mr-32'>
              <Hidden xsDown>
                <img src='/assets/icons/logo-black.svg' height={40} className='mr-40' />
              </Hidden>
            </NavLink>
          </Box>
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Menu</h2>
            <a className='footer_link' href='https://spores.app/explore'>
              Marketplace
            </a>
            <a className='footer_link' href='https://launchpad.spores.app/'>
              Lootbox
            </a>
            <a className='footer_link' href='https://launchpad.spores.app/'>
              Launchpad
            </a>
            <a className='footer_link' href='https://staking.spores.app/'>
              Staking
            </a>
          </Box>
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Community</h2>
            <a className='footer_link' href='https://spores.nolt.io/'>
              Suggest Feature
            </a>
            <a className='footer_link' href=''>
              Help Center
            </a>
          </Box>
          <Box className='align-items-center col-sm-2 footer-menu'>
            <h2>Legal</h2>
            <a className='footer_link' href='https://staking.spores.app/terms-of-use'>
              Terms Of Use
            </a>
            <a className='footer_link' href='https://staking.spores.app/privacy-policy'>
              Privacy Policy
            </a>
            <a className='footer_link' href='https://staking.spores.app/cookie-statement'>
              Cookie Statement
            </a>
          </Box>
          <Box className='col-sm-3 footer-contact'>
            <h2>Contact</h2>
            <div className='row col-12 pl-0'>
              <a className='footer_link' href='https://t.me/sporeseng'>
                <img src={images.s_1} />
              </a>
              <a className='footer_link' href='https://spores.medium.com/'>
                <img src={images.s_2} />
              </a>
              <a className='footer_link' href='https://www.linkedin.com/company/sporesnetwork/'>
                <img src={images.s_3} />
              </a>
              <a className='footer_link' href='https://twitter.com/Spores_Network/'>
                <img src={images.s_4} />
              </a>
            </div>
            <div className='row col-12 mt-3 pl-0'>
              <a className='footer_link' href='https://www.youtube.com/channel/UC9pgjF_aWprnVodvxO7OPkA/about/'>
                <img src={images.s_5} />
              </a>
              <a className='footer_link' href='https://www.facebook.com/SporesNetwork/'>
                <img src={images.s_6} />
              </a>
              <a className='footer_link' href='https://www.instagram.com/SporesNetwork/'>
                <img src={images.s_7} />
              </a>
              <a className='footer_link' href=''>
                <img src={images.s_8} />
              </a>
            </div>
          </Box>
        </Container>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  containerTop: {
    backgroundColor: theme.palette.primary.light,
    padding: '32px 0px',
  },

  containerBottom: {
    backgroundColor: '#242424',
    color: '#e3e3e3',
    padding: '16px 0px',
  },
  link: {
    color: '#e3e3e3',
    marginRight: 32,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

export default Footer;
