import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import './boxProfile.scss';
import fb from 'assets/images/fb.png';
import twiter from 'assets/images/twiter.png';
import instagram from 'assets/images/instagram.png';
import ball from 'assets/images/ball.png';
import tiktok from 'assets/images/tiktok.png';

class Profile extends Component {
  render() {
    return (
      <Fragment>
        <div className='profile-detail row'>
          <div className='col-md-3 col-xs-0 col-sm-0 div-hide'></div>
          <div className='top-profile col-md-6 col-sm-12 col-xs-12'>
            {/* <ul className="infor">
                            <li className="item">
                                <div className="number">150</div>
                                <div className="type">Items</div>
                            </li>
                            <li className="item">
                                <div className="number">1M</div>
                                <div className="type">Followers</div>
                            </li>
                            <li className="item">
                                <div className="number">150</div>
                                <div className="type">Likes</div>
                            </li>
                        </ul> */}
            <span className='content-profile' style={{ marginTop: '15px' }}>
              Hey! I'm Vedad , 20 years old and I am a variety Streamer from Bosnia! Around here we just aim to have
              lovely time together and c...
            </span>
            <span className='more'>See more</span>
            <ul className='social'>
              <li>
                <img src={fb} alt='' />
              </li>
              <li>
                <img src={instagram} alt='' />
              </li>
              <li>
                <img src={ball} alt='' />
              </li>
              <li>
                <img src={tiktok} alt='' />
              </li>
              <li>
                <img src={twiter} alt='' />
              </li>
            </ul>
          </div>
          <div className='col-md-3 col-sm-12 col-xs-12 button-res '>
            <Button className='edit-btn float-right btn-mark' style={{ lineHeight: '16px', marginTop: 23 }}>
              Edit profile
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default Profile;
