export const API_URI = process.env.REACT_APP_API_URI || process.env.NEXT_PUBLIC_API_URI;
export const API_URI_LOOTBOX = process.env.REACT_APP_LOOTBOX_API || 'https://api-lootbox-uat.spores.dev';
export const MAINNET = process.env.REACT_APP_MAINNET || process.env.NEXT_PUBLIC_MAINNET;
export const PAYMENT_CONTRACT = process.env.REACT_APP_PAYMENT_CONTRACT || '0x7931f3490542e5F818a53C738D77FB802a8320DB';
export const FACTORY_CONTRACT = process.env.REACT_APP_FACTORY_CONTRACT || '0xB60b611692f375CBAd4c311Af1FA541938589866';
export const VENDER_CONTRACT = process.env.REACT_APP_VENDER_CONTRACT || '0xEB9F62485CE0B39022FC81A02c224C11118500DF';
export const USDT_CONTRACT = process.env.REACT_APP_USDT_CONTRACT || '0x65a5100135f159b292050966bf9f5b94cee4b882';
export const USDC_CONTRACT = process.env.REACT_APP_USDC_CONTRACT || '0xc6daa3cbe8a7e79f6762b445e96205b21d58ab0f';
export const BUSD_CONTRACT = process.env.REACT_APP_BUSD_CONTRACT || '0xe06880fb3e14ba4c23b2751f720a1cec32e784e0';
export const SPO_CONTRACT = process.env.REACT_APP_SPO_CONTRACT || '0xaba288930f78702e2c1b898ade092769c0bee295';
export const MAX_PRICE = process.env.REACT_APP_MAX_PRICE;
export const MIN_PRICE = process.env.REACT_APP_MIN_PRICE;

export const GAS = process.env.REACT_APP_GAS || 300000;
