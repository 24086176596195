import React from 'react';
import { Box, Divider, InputBase, MenuItem, Select, Typography } from '@material-ui/core';
import { Table } from 'antd';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Transaction',
    dataIndex: 'item',
    key: 'image',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
  {
    title: 'From',
    dataIndex: 'sender',
    key: 'sender',
  },
  {
    title: 'To',
    dataIndex: 'receiver',
    key: 'receiver',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

const TabCollectionHistory = () => {
  const classes = useStyles();
  const [limit, setLimit] = React.useState(20);

  return (
    <>
      <Table dataSource={[]} columns={columns} style={{ width: '100%' }} pagination={false} />
      <Box className='flex-row align-items-center justify-content-end mt-24'>
        <Typography>Show</Typography>
        <Select
          value={limit}
          onChange={(event) => setLimit(event.target.value)}
          className={classes.selectBox}
          input={<InputBase />}
        >
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>

        <Divider orientation='vertical' style={{ height: 20, margin: '0px 10px' }} />
        <Pagination count={10} variant='outlined' shape='rounded' />
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  selectBox: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #ced4da',
    padding: '0 10px',
    marginLeft: '10px',
  },
}));

export default TabCollectionHistory;
