import React, { Fragment } from 'react';

import './open.scss';
import { Button } from 'antd';
import Item from './Item';
import { useHistory } from 'react-router';


export default function Legend(props) {
  const { openDetail, loading } = props;
  const history = useHistory();
  const genClass = () => {
    if (openDetail) {
      if (!loading) {
        switch (openDetail.nft_type?.id) {
          case 1:
            return 'legend-open legend-class';
          case 2:
            return 'legend-open epic-class';
          case 3:
            return 'legend-open super-rare ';
          case 4:
            return 'legend-open rare';
          case 5:
            return 'legend-open common';
          default:
            return 'legend-open loading-class';
        }
      }
    }

    return 'legend-open loading-class';
  };
  return (
    <Fragment>
      <div className='open-b'>
        <div className={genClass()}>
          <div className='item-open'>
            <Item loading={loading} openDetail={openDetail && openDetail !== '<nil>' ? openDetail : null} />
          </div>
          <Fragment>
            <div style={{ visibility: loading ? 'hidden' : '' }}>
              <div className='name' style={{ textAlign: 'center' }}>
                {openDetail && openDetail.user_nft?.name}
              </div>
              {/* <div className="level1">
                       <img className="image-item" width="120px" src={rarity} />
                   </div> */}
              <div className='group-btn-box d-flex'>
                <Button onClick={() => history.push('/app/box-profile')} className='btn-lb'>
                  Go to profile
                </Button>
                <Button
                  onClick={() => history.push(`/app/item-detail-sale/${openDetail && openDetail.user_nft?.id}`)}
                  className='buy view'
                >
                  View details
                </Button>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}
