import BoxMarket from 'components/boxprofile/BoxMarket';
import CampaignHeader from 'components/campaign/campaign';
import SlideCampaign from 'components/campaign/slidecampaign';
import React, { Fragment, useEffect, useState } from 'react';
import { lootboxService } from 'services/lootbox/lootbox';
import { userServiceLb } from 'services/lootbox/user';
import './market.css';

const Market = () => {
  const [profile, setProfile] = useState(null);
  const [lootbox, setLootbox] = useState([]);

  useEffect(async () => {
    const res2 = await lootboxService.lootboxList();
    setLootbox(res2.data);
  }, []);

  return (
    <Fragment>
      <CampaignHeader />
      <div className='market-page'>
        <div className='container pl-32 pr-32'>
          <BoxMarket lootbox={lootbox} />
          <SlideCampaign slide_name='Campaigns' />
        </div>
      </div>
    </Fragment>
  );
};
export default Market;
