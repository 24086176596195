import DropItem from './DropItem';
import React, { Fragment, useEffect, useState } from 'react';
import './boxProfile.scss';
import DropItemBox from './DropItemBox';
import { Button, List, Menu, Select, Space, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { CaretUpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const RowDropItem = (props) => {
  const { items, type, boxCampaignFilter, page } = props;
  const [dropFilter, setDropFilter] = useState([]);
  const [modal, setModal] = useState(false);
  const { boxItem } = useSelector(({ common }) => common);
  const handleClickFilter = () => {
    if (boxItem && modal) {
      const filter = boxCampaignFilter.filter(
        (f) =>
          f.CampaignWave.lootbox_campaign_id == boxItem.CampaignWave.lootbox_campaign_id && items.includes(boxItem),
      );
      if (filter && filter.length > 0) {
        setDropFilter(filter);
      } else {
        setDropFilter([]);
        setModal(false);
      }
    }
  };
  useEffect(() => {
    setModal(false);
  }, [page]);

  const handleChangeStatus = (i) => {
    if (!modal) {
      setModal(true);
    } else {
      if (i.id == boxItem.id) {
        setModal(false);
      } else {
        setModal(true);
      }
    }
  };

  const makeUrl = (id, t) => {
    if (type == 'open') {
      return;
    }
    if (t == 'box') {
      return '/app/detail-box-open/' + id;
    }
    if (t == 'item' && type != 'onsell') {
      return '/app/item-detail-sale/' + id;
    }
    if (type == 'onsell' && t == 'item') {
      return '/app/item-sale/' + id;
    }
  };

  useEffect(() => {
    if (modal && boxItem) {
      handleClickFilter();
    }
  }, [modal, boxItem]);

  return (
    <Fragment>
      <div className={`list-drop-items ${type == 'open' ? ' bongmo' : ''}`}>
        <div className='row list-item'>
          {items &&
            items.map((i) => {
              return (
                <div key={i.id} className='drop-group col-3'>
                  <DropItemBox
                    clickItem={handleChangeStatus}
                    key={i.id}
                    item={i}
                    type={type}
                    className={type}
                    items={items}
                  />
                  {modal &&
                    boxItem &&
                    boxItem.CampaignWave.lootbox_campaign_id == i.CampaignWave.lootbox_campaign_id && (
                      <CaretUpOutlined className='arrow-item' style={{ fontSize: '24px', color: '#DFE0E6' }} />
                    )}
                </div>
              );
            })}
        </div>
        {modal && dropFilter && dropFilter.length > 0 && (
          <List
            className='list-item-propup'
            grid={{
              gutter: 16,
              xs: 2,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={dropFilter}
            renderItem={(i) => (
              <Fragment>
                <List.Item
                // onClick={() => {
                //     handleRedirect(i.id, i.type);
                // }}
                >
                  {/*<a href={ makeUrl(i.id,i.type) }><DropItem item={i} type={type} className={type} /></a>*/}
                  <Link to={makeUrl(i.id, i.type)}>
                    <DropItem item={i} type={type} className={type} items />
                  </Link>
                </List.Item>
              </Fragment>
            )}
          />
        )}
      </div>
    </Fragment>
  );
};
export default RowDropItem;
