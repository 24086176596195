/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState } from 'react';
import './DetailCampaign.scss';
import { Statistic, Progress, Button } from 'antd';
import { images } from 'assets/images';
import DropItem from './component/DropItem/DropItem';
import RelatedArtist from 'views/ExploreView/components/RelatedArtist';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import CheckoutBox from 'components/campaign/CheckoutBox.js';
import campaign from 'reducers/loobox/campaign';
import { campaignService } from 'services/lootbox/campaign';
import { Select } from 'antd';
import { API_URI_LOOTBOX } from 'env';
import { useSelector } from 'react-redux';
import ShowMoreText from "react-show-more-text";
var momentDurationFormatSetup = require('moment-duration-format');
momentDurationFormatSetup(moment);

const { Option } = Select;
const { Countdown } = Statistic;
const deadline = Date.now() + 1000 * 60 * 60 * 24 * 2 + 1000 * 30;
const no_data_amet =
  'no-data Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet';

const endtime = 100000000000000;

export default function DetailCampain(props) {
  const [durationDiff, setDurationDiff] = useState(null);
  const history = useHistory();
  const [dropItem, setDropItem] = useState([]);
  const [arrayTotal, setArrayTotal] = useState({});
  const [campaign, setCampaign] = useState(null);
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const { currencySystem } = useSelector(({ common }) => common);
  const [priceShow, setPriceShow] = useState('SPO');
  const [status, setStatus] = useState('BUY NOW');

  useEffect(() => {
    if (campaign) {
      countDownTime();
    }
  }, [campaign]);
  const { slug,waveName } = useParams();
  function countDownTime() {
    const eventTime = moment(campaign.campaign_wave.end_time).unix();
    const start_time = moment(campaign.campaign_wave.start_time).unix();
    const currentTime = moment().unix();
    if (currentTime > eventTime) {
      return;
    }
    let diffTime = start_time - currentTime;
    setStatus('COMING SOON');
    if (diffTime <= 0) {
      setStatus('BUY NOW');
      diffTime = eventTime - currentTime;
    }
    let ress = moment.duration(diffTime * 1000, 'milliseconds');
    const idInterval = setInterval(function () {
      ress = moment.duration(ress - 1000, 'milliseconds');
      if (ress >= 0) {
        setDurationDiff(ress);
      } else {
        setDurationDiff(0);
        clearInterval(idInterval);
        getData();
      }
    }, 1000);
  }
  useEffect(() => {
    if (campaign) {
      if (campaign.campaign_wave.first_max_quantity - campaign.campaign_wave.first_sold_box_quantity > 0) {
        setPriceShow('SPO');
      } else if (campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity > 0) {
        setPriceShow('USDT');
      }
      checkActive();
    }
  }, [campaign]);

  const checkActive = () => {
    const nowDate = new Date().getTime();
    const endDate = new Date(campaign.campaign_wave.end_time).getTime();
    const startDate = new Date(campaign.campaign_wave.start_time).getTime();
    if (startDate < nowDate) {
      setStatus('BUY NOW');
    } else {
      setStatus('COMING SOON');
    }
  };

  const checkRemain = (type) => {
    if (campaign) {
      if (
        (campaign &&
          campaign.campaign_wave.first_max_quantity - campaign.campaign_wave.first_sold_box_quantity <= 0 &&
          type == 'SPO') ||
        (campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity <= 0 &&
          type != 'SPO')
      ) {
        return false;
      }
      return true;
    }
  };

  const getData = async () => {
    let res = null;
    if (isLoggedIn) {
      res = await campaignService.slugUserCampaignWave(waveName+"-"+slug);
    } else {
      res = await campaignService.slugCampaignWave(waveName+"-"+slug);
    }

    if (!res) {
      return;
    }
    setCampaign(res.data);

    if (!res.data || !res.data.campaign_wave.lootbox_campaign.nfts) {
      return;
    }
    let summedAges = res.data.campaign_wave.lootbox_campaign.nfts.reduce((a, c) => {
      let filtered = a.filter((el) => el.nft_type.id === c.nft_type.id);
      if (filtered.length > 0) {
        a[a.indexOf(filtered[0])].total_supply += +c.total_supply;
        a[a.indexOf(filtered[0])].amount += +1;
      } else {
        const tem = { ...c, amount: 1 };
        a.push(tem);
      }
      return a;
    }, []);

    summedAges.sort((a, b) => a.nft_type.id - b.nft_type.id)
    // eslint-disable-next-line no-extend-native
    Array.prototype.sum = function (prop) {
      var total = 0;
      for (var i = 0, _len = this.length; i < _len; i++) {
        total += this[i][prop];
      }
      return total;
    };
    let arraySum = {
      totalAmount: summedAges.sum('amount'),
      totalSupply: summedAges.sum('total_supply'),
    };
    console.log('summedAges =-====>', summedAges)
    setArrayTotal(arraySum);
    setDropItem([...summedAges]);
  };

  useEffect(async () => {
    getData();
  }, [isLoggedIn]);

  const handleSerialCOntent = () => {};

  const handleImage = (name) => {
    if (name == 'Legendary') {
      return '';
    }
    if (name == 'Epic') {
      return images.rarity1;
    }
    if (name == 'Supper Rare') {
      return images.rarity2;
    }
    if (name == 'Rare') {
      return images.rarity3;
    }
    // if (name == "Rare 2") {
    //     return images.rarity4;
    // }
    return images.rarity4;
  };
  // const prog2 = campaign ? (campaign.campaign_wave.second_max_quantity - campaign.campaign_wave.second_sold_box_quantity) / campaign.campaign_wave.second_max_quantity * 100 : 0;
  const prog1 = campaign
    ? ((campaign.campaign_wave.max_box_quantity -
        campaign.campaign_wave.first_sold_box_quantity -
        campaign.campaign_wave.second_sold_box_quantity) /
        campaign.campaign_wave.max_box_quantity) *
      100
    : 0;
  const genClass = (name) => {
    if (name == 'Legendary') {
      return 'rarity_btn rarity_legend';
    }
    if (name == 'Epic') {
      return 'rarity_btn rarity_epic';
    }
    if (name == 'Super Rare') {
      return 'rarity_btn rarity_super_rare';
    }
    if (name == 'Rare') {
      return 'rarity_btn rarity_rare';
    }
    return 'rarity_btn rarity_common';
  };


  return (
    <div className='row detail-page'>
      <img src={images.campaign_cover} className='banner-header' />
      <div className='col-12 banner-content'>
        <div className='infor-content row'>
          <div className='col-lg-6 col-xs-12 left'>
            <div className='box-left'>
              <h3 className='title mb-2'>Campaign detail</h3>
              {/*<div className='text-intro'>{campaign && campaign.campaign_wave.lootbox_campaign.content}</div>*/}
              <ShowMoreText
                  /* Default options */
                  lines={3}
                  more="See more"
                  less="See less"
                  className="text-intro"
                  anchorClass="see-more-less"
                  expanded={false}
                  width={530}
                  truncatedEndingComponent={"... "}
              >{campaign && campaign.campaign_wave.lootbox_campaign.content}</ShowMoreText>
              <h3 className='title mt-4'>Series Content</h3>
              <div className='table'>
                <div className='title-table'>
                  <div className='rarity'>Rarity</div>
                  <div className='amount'>Amount</div>
                  <div className='total'>Total Minted </div>
                  <div className='probability'>Probability</div>
                </div>
                <div className='value-table'>
                  {dropItem.map((data, index) => (
                    <div className='item-table' key={index}>
                      {/* <img className='rarity' src={handleImage(data.rarity_name)} /> */}
                      <div className='item-name' style={{ width: '120px', textAlign: 'start' }}>
                        {/*<Button className={genClass(data.nft_type.label)}> {data.nft_type.label}</Button>*/}
                        <h5 className={genClass(data.nft_type.label)}>{data.nft_type.label}</h5>
                      </div>

                      <div className='amount'>{data.amount}</div>
                      <div className='total'>{data.total_supply}</div>
                      <div className='probability'>
                        {data && Number((parseFloat(data.total_supply / arrayTotal.totalSupply) * 100).toFixed(2))} %
                      </div>
                    </div>
                  ))}
                  <div className='total-table'>
                    <div className='rarity'>Total</div>
                    <div className='amount'>{arrayTotal ? arrayTotal.totalAmount : ''}</div>
                    <div className='total'>{arrayTotal ? arrayTotal.totalSupply : ''}</div>
                    <div className='probability'>100%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-xs-12 right'>
            <div className='campain-start'>
              <div className='box-right'>
                <div className='col-lg-12 d-flex justify-content-center p-0'>
                  <h3 className='title'>
                    {campaign && campaign.campaign_wave.lootbox_campaign.title}{' '}
                    {campaign && campaign.campaign_wave.wave_name && '-' + campaign.campaign_wave.wave_name}
                  </h3>
                </div>
                <div className='col-lg-12 p-0'>
                  <Fragment>
                    <div className='row mr-4 remaining'>
                      <h4 className='sub-title col-6 float-left'>Remaining</h4>
                      <h4 className='sub-title col-6 float-right' style={{ textAlign: 'right' }}>
                        {campaign &&
                          campaign.campaign_wave.max_box_quantity -
                            (campaign.campaign_wave.first_sold_box_quantity +
                              campaign.campaign_wave.second_sold_box_quantity)}{' '}
                        / {campaign && campaign.campaign_wave.max_box_quantity}
                      </h4>
                    </div>
                    <div className='line'>
                      <Progress
                        type='line'
                        percent={campaign ? prog1.toFixed(2) : 100}
                        trailColor='#535353'
                        strokeColor='#FFC700'
                        showInfo={false}
                      />
                    </div>
                  </Fragment>
                  {/* {priceShow != "SPO" && <Fragment>
                    <div className='row mr-4 remaining'>
                      <h4 className='sub-title col-6 float-left'>USDT,USDC,BUSD</h4>
                      <h4 className='sub-title col-6 float-right' style={{ textAlign: 'right' }}>{campaign && campaign.campaign_wave.second_max_quantity - (campaign.campaign_wave.second_sold_box_quantity)} / {campaign && campaign.campaign_wave.second_max_quantity}</h4>
                    </div>
                    <div className='line'>
                      <Progress type="line" percent={campaign ? prog2.toFixed(2) : 100} trailColor="#535353" strokeColor="#CCB04D" />
                    </div></Fragment>} */}
                </div>
                <div className='col-lg-12 d-flex justify-content-center lootbox-image p-0 mb-2'>
                  <img src={campaign ? campaign.campaign_wave.lootbox_campaign.box_image : ''} className='mt-2' />
                </div>
                <div className='col-lg-12 d-flex justify-content-center flex-column align-items-center box-countdown p-0'>
                  <div className='d-flex time-down-start-end'>
                    <span className='campain-end'>
                      {status == 'BUY NOW' ? 'Campaign ends in' : 'Campaign start in'}
                    </span>
                    {/* <Countdown value={deadline} target={deadline} /> */}
                    <div className='time-countdown'>
                      <div className='item-time'>
                        <div className={`box-time ${+durationDiff?._milliseconds < 3600000 && 'box-time-red'}`}>
                          {moment.duration(durationDiff).format('DD:hh:mm:ss', { trim: false })}
                        </div>
                      </div>
                    </div>
                    {/* <img src={images.texttime} /> */}
                  </div>
                  <div className='time-zone mt-1'>
                    <div className='currency-text'>Select Currency</div>
                    {campaign && currencySystem && (
                      <Select
                        className='currency-select'
                        onChange={(e) => setPriceShow(e)}
                        value={priceShow}
                        width='100%'
                      >
                        {currencySystem &&
                          currencySystem.map((m) => {
                            if (checkRemain(m.label))
                              return (
                                <Option key={m.id} value={m.label}>
                                  {m.label}
                                </Option>
                              );
                          })}
                      </Select>
                    )}
                    <div>
                      <div className='bl-r'>
                        <span className='box-price-title'>Remaining</span>
                        <div>
                          {campaign && priceShow == 'SPO' && (
                            <span className='price'>
                              {campaign.campaign_wave.first_max_quantity -
                                campaign.campaign_wave.first_sold_box_quantity}{' '}
                              boxes
                            </span>
                          )}
                          {campaign && priceShow != 'SPO' && (
                            <span className='price'>
                              {campaign.campaign_wave.second_max_quantity -
                                campaign.campaign_wave.second_sold_box_quantity}{' '}
                              boxes
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='bl-r' style={{ paddingTop: 0 }}>
                        <span className='box-price-title'>Price per box</span>
                        <div>
                          <span className='price'>
                            {campaign
                              ? priceShow == 'SPO'
                                ? campaign.campaign_wave.first_price
                                : campaign.campaign_wave.second_price
                              : ''}{' '}
                            {priceShow}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className='btn-follow' onClick={() => history.push(`/app/box-detail/${id}`)}>FOLLOW CAMPAIGN</div> */}
                    <CheckoutBox campaign={campaign} priceShow={priceShow} status={status} refresh={getData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row col-12 all-list'>
        <DropItem nfts={campaign} dropItem={dropItem} arrayTotal={arrayTotal} />
      </div>
      <div className='row col-12 about-artist'>
        <div className='row col-12'>
          <div className='img-artist col-6'>
            <img src={campaign ? campaign.campaign_wave.lootbox_campaign.artist_avatar : ''} className='img-art' />
          </div>
          <div className='text-artist col-6'>
            <h3>About artist</h3>
            {/* <div className='infor-art d-none'>
              <img src={images.thumb_coin} width='48' height='48' />
              <div className='name-art'>
                <div>Join smith</div>
                <div>2x2a0d2cdf...8b6f3bedbb</div>
              </div>
            </div> */}
            <div className='intro-art'>
              <h2>{campaign && campaign.campaign_wave.lootbox_campaign.artist_name}</h2>
              <div>{campaign && campaign.campaign_wave.lootbox_campaign.artist_description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
