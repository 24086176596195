import dynamic from 'next/dynamic';

export { default as PerfectScrollbar } from 'react-perfect-scrollbar';
export { default as InfiniteScroll } from 'react-infinite-scroller';
export { default as AvatarEditor } from 'react-avatar-editor';

export { default as Loading } from './Loading';
export { default as LoadingLinear } from './LoadingLinear';
export { default as ButtonSelect } from './ButtonSelect';
export { default as ColorButton } from './ColorButton';
export { default as StyledChip } from './StyledChip';
export { default as InputNumberFormat } from './InputNumberFormat';
export { default as CountdownTimer } from './CountdownTimer';
export { default as BackgroundColor } from './BackgroundColor';

export { default as SearchBar } from './SearchBar';
export { default as LanguageBar } from './LanguageBar';
export { default as NetworkBar } from './NetworkBar';

export const Darkmode = dynamic(() => import('./Darkmode'), { ssr: false });
