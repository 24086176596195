import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, CardMedia, Divider, Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { normalizeCollection } from 'utils/converter';
import { privateRoute } from 'routes';

const CardCollection = ({ item: apiItem }) => {
  const item = React.useMemo(() => normalizeCollection(apiItem), [apiItem]);

  return (
    <Card variant='outlined' className='Card-Container'>
      <CardMedia
        image={item.image}
        component={Link}
        to={privateRoute.collectionView.url(item.id)}
        style={{ height: 120 }}
      >
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Divider />
      <Box className='flex-column align-items-center' style={{ padding: '8px 12px' }}>
        <Box style={{ marginTop: -48 }}>
          <Avatar src={item.image} style={{ width: 60, height: 60, border: '2px solid #fff' }} />
        </Box>
        <Typography variant='subtitle1' align='center' className='text-ellipsis' title={item.name}>
          <Link to={privateRoute.collectionView.url(item.id)}>{item.name}</Link>
        </Typography>
        <Typography variant='subtitle2' color='textSecondary' align='center'>
          {item.symbol}
        </Typography>
      </Box>
    </Card>
  );
};

const CardSkeleton = () => {
  return (
    <Card variant='outlined' style={{ borderRadius: 8 }}>
      <CardMedia style={{ height: 120 }}>
        <Skeleton variant='rect' width='100%' height='100%' />
      </CardMedia>
      <Divider />
      <Typography style={{ padding: '12px 12px 4px' }}>
        <Skeleton />
      </Typography>
      <Typography style={{ padding: '4px 12px 8px' }}>
        <Skeleton />
      </Typography>
    </Card>
  );
};

CardCollection.Skeleton = CardSkeleton;

export default CardCollection;
