import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Avatar, Container, Box, Typography, Paper, Divider, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Spin } from 'antd';
import { ListingSearch, TabCollectionHistory, TabCollectionListing } from './components';
import { normalizeCollection } from 'utils/converter';
import { parse, stringify } from 'query-string';
import { marketService } from 'services/market';

const CollectionView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { id } = useParams();
  const { tab, ...query } = parse(location.search);

  const [isLoading, setIsLoading] = React.useState(false);
  const [apiItem, setItem] = React.useState({});

  const collection = React.useMemo(() => normalizeCollection(apiItem), [apiItem]);

  const fetchData = React.useCallback(() => {
    setIsLoading(true);
    marketService
      .fetchSubCollections()
      .then(({ data }) => {
        setItem(data.subCollections.find((item) => item.id === id));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const tabs = [
    { id: 1, code: 'listing', label: 'Listing', component: <TabCollectionListing /> },
    { id: 2, code: 'history', label: 'Trading History', component: <TabCollectionHistory /> },
  ];
  const [activeTab, setActiveTab] = React.useState((tabs.find((item) => item.code === tab) ?? tabs[0]).code);

  const handleChangeTab = (_, nextTab) => {
    setActiveTab(nextTab);
    window.history.replaceState(null, null, '?' + stringify({ ...query, tab: nextTab }));
  };

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container>
      <Spin spinning={isLoading}>
        <Box
          height={280}
          className={classes.cover}
          style={collection.id ? { backgroundImage: `url(${collection.image})` } : {}}
        />
        <Box className='justify-content-center'>
          <Avatar src={collection.id && collection.image} className={classes.image} />
        </Box>

        <Box className='flex-column align-items-center mt-12'>
          <Typography variant='h4'>{collection.name}</Typography>
          <Typography variant='h6' color='textSecondary' gutterBottom>
            {collection.symbol}
          </Typography>
          <Box className='flex-row align-items-center mb-24'>
            <Avatar className={classes.avatar} />
            <Typography>{collection.creatorAddr}</Typography>
          </Box>

          <Paper className={classes.board + ' flex-row align-items-center mb-24'}>
            <Box flex={1} className='flex-column justify-content-center align-items-center'>
              <Typography variant='h6'>{collection.NFTs ?? '-'}</Typography>
              <Typography color='textSecondary'>NFTs</Typography>
            </Box>
            <Divider orientation='vertical' style={{ height: 40 }} />
            <Box flex={1} className='flex-column justify-content-center align-items-center'>
              <Typography variant='h6'>{collection.Availables ?? '-'}</Typography>
              <Typography color='textSecondary'>Availables</Typography>
            </Box>
            <Divider orientation='vertical' style={{ height: 40 }} />
            <Box flex={1} className='flex-column justify-content-center align-items-center'>
              <Typography variant='h6'>{collection.Owners ?? '-'}</Typography>
              <Typography color='textSecondary'>Owners</Typography>
            </Box>
          </Paper>

          <Typography color='textSecondary' style={{ maxWidth: 720 }}>
            {collection.description}
          </Typography>
        </Box>
      </Spin>

      <Box style={{ position: 'relative', marginTop: 60 }}>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          {tabs.map((tab) => (
            <Tab key={tab.id} label={tab.label} value={tab.code} style={{ minWidth: 80 }} />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <Box key={tab.id} hidden={tab.code !== activeTab} py={3}>
            {tab.component}
          </Box>
        ))}

        {activeTab === 'listing' && (
          <Box className={classes.searchBar}>
            <ListingSearch />
          </Box>
        )}
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  cover: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderRadius: 16,
  },
  image: {
    width: 180,
    height: 180,
    border: '4px solid #fff',
    marginTop: -120,
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  board: {
    width: 480,
    height: 80,
    borderRadius: 40,
  },
  searchBar: {
    position: 'absolute',
    top: 6,
    right: 0,
  },
}));

export default CollectionView;
